import { TaskParametersType } from 'types';
import { INFORMATION_TAB_NAME } from '../components/Ticket/constants';

export const removeSpacesFromNumber = (number) => {
  try {
    if (number) {
      const numberWithoutSpaces = number.replace(/ /g, '');
      const fixedNumber = parseFloat(numberWithoutSpaces).toFixed(5);
      if (isNaN(Number(fixedNumber))) {
        return 0;
      }
      return Number(fixedNumber);
    }
    return 0;
  } catch (e) {
    return number;
  }
};

export const sortArrayOfObjects = (a, b, key, order = 'asc') => {
  const valueA = a[key];
  const valueB = b[key];

  if (valueA < valueB) {
    return order === 'asc' ? -1 : 1;
  }

  if (valueA > valueB) {
    return order === 'asc' ? 1 : -1;
  }

  return 0;
};

export const parseJSON = (value: string) => {
  try {
    return value ? JSON.parse(value) : {};
  } catch (e) {
    return {};
  }
};

export const isObjectEmpty = (value) => value && Object.keys(value).length > 0;

export const isObjectAttributesEmpty = (object) => !Object.values(object).some((x) => x !== undefined && x !== null && x !== '');

export const getAttributeNames = (groupsByTabs) => {
  const informationTabGroups = groupsByTabs[INFORMATION_TAB_NAME] || {};
  const informationTabGroupsAttributes = (Object.values(informationTabGroups) || []).flat();
  return informationTabGroupsAttributes.map((attribute) => (attribute as TaskParametersType).name);
};

export const numberWithSpaces = (x) => {
  const parts = x?.toString()?.split('.');
  parts[0] = parts?.[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return parts?.join('.');
};

export const wait = async (milliseconds: number) => new Promise((res) => setTimeout(res, milliseconds));
