import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  clearSelectedRequest,
  clearTaskData,
  openCreateDialogAction as openCreateDialog,
} from 'store/requests';

import { routesList } from 'router';
import { SubmenuItem } from 'types';
import { sendCustomEvent } from '../../utils/analytics';

export const useRequests = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const {
    search,
    settings,
    requests: { path: requestPath, submenu: routesMenuList },
  } = routesList(t);

  const {
    isMassSelectAvailable,
    isFiltersAvailable,
  } = useMemo((): {
    isMassSelectAvailable: boolean;
    isDataViewAvailable: boolean;
    isFiltersAvailable: boolean;
  } => {
    const currentRoute = routesMenuList.find(({ path }: SubmenuItem): boolean =>
      pathname.includes(requestPath + path)
    );

    const {
      isMassSelectAvailable = false,
      isDataViewAvailable = false,
      isFiltersAvailable = false,
    } = currentRoute?.props || {};

    return {
      isMassSelectAvailable,
      isDataViewAvailable,
      isFiltersAvailable,
    };
  }, [pathname]);

  const openDialog = (): void => {
    dispatch(clearTaskData());
    dispatch(openCreateDialog(true));
  };

  const navigateToSearch = (): void => {
    history.push(search.path);
  };

  const navigateToProcessSettings = (): void => {
    sendCustomEvent('Requests_Custom_Processes', 'View_Groups_via_click_settings', 'ViewGroups');
    history.push(settings.path + settings.submenu[1].path, { from: '/processes' + history.location.pathname + history.location.search });
  };

  useEffect(() => {
    return () => {
      dispatch(clearSelectedRequest())
    }
  }, [])

  return {
    menuList: routesMenuList,
    requestPath,
    isFiltersAvailable,
    isMassSelectAvailable,
    openDialog,
    navigateToSearch,
    navigateToProcessSettings,
  };
};
