import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';

import { getProfileCompanyDataFromLocalStorage } from 'utils/user';
import { changeCurrentWorkspace } from 'utils/workspace';
import { useUserProfile } from 'hooks';

import { ChangeAssigneeCallbackContext, ChangeAssigneeData } from '../AdminPanel';
import { RequestPageContent } from './RequestPageContent';

export const RequestPage = () => {
  const { id: paramId } = useParams<{ id?: string }>();
  const { id: currentUserId, nickname } = useUserProfile();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [requestClosed, setRequestClosed] = useState(false);
  const [readyToShowRequest, setReadyToShowRequest] = useState(false);
  const [changeAssigneeData, setChangeAssigneeData] = useState<ChangeAssigneeData>(null);

  const openCorrectProcessStep = async (processInstanceId) => {
    setRequestClosed(false);
    const { id: currentWorkspaceId } = getProfileCompanyDataFromLocalStorage();
    const taskWorkspaceId =
      queryString.parse(location.search)?.workspaceId || currentWorkspaceId;
    try {
      if (taskWorkspaceId !== currentWorkspaceId) {
        await changeCurrentWorkspace(taskWorkspaceId);
      } else {
        setReadyToShowRequest(true);
      }
    } finally {
      setLoading(false);
      setRequestClosed(false);
    }
  };

  useEffect(() => {
    openCorrectProcessStep(paramId);
  }, [paramId, currentUserId]);

  return (
    <ChangeAssigneeCallbackContext.Provider
      value={{
        action: ({ assigneeId, taskId, stepName }) => {
          setChangeAssigneeData({
            assigneeId,
            taskId,
            stepName
          });
        },
        isAvailiable: true
      }}
    >
      <RequestPageContent
        loading={loading}
        readyToShowRequest={readyToShowRequest}
        requestClosed={requestClosed}
        changeAssigneeData={changeAssigneeData}
        setRequestClosed={setRequestClosed}
        setChangeAssigneeData={setChangeAssigneeData}
      />
    </ChangeAssigneeCallbackContext.Provider>
  );
};

export default RequestPage;
