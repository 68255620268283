import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  wrapper: {
    height: "auto",
    width: '100%',
    position: 'fixed',
    top: 24,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  wrapperInactive: {
    pointerEvents: 'none',
    top: 0,
  },
  root: {
    height: 48,
    background: "#137149",
    color: "#FFFFFF",
    boxShadow: "0px 16px 32px rgba(0, 0, 0, 0.04)",
    borderRadius: 12,
    marginTop: 8,
    padding: "12px 16px",
    fontSize: 14,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  closeIcon: {
    height: 16,
    width: 16,
    marginLeft: 8
  },
  checkmarkIcon: {
    height: 24,
    width: 24,
    marginRight: 16,
  }
}));

export default useStyles;
