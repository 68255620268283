import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';

import {
  DocumentTemplate, DocumentTemplateField,
} from '../../../TemplateProcesses.types';
import { getDocumentTemplateById } from 'api/requests';
import { initializeSelectedStates } from 'components/Glossary/utils';
import { useUsersState } from '../../../../../store/users';
import i18n from 'i18next';
import { getDisplayedStepOrder } from '../../../TemplateProcesses.utils';

export const useProcessDocuments = ({
  fields,
  conditions,
  parallelBranchesGroups,
  directories,
  allDirectories,
  documents,
  focusedDocumentId,
  isNewDocument,
  currentProcessIndex,
  setFocusedDocumentId,
  setPreviewedDocumentTemplateId,
  setPreviewDocumentToolbarFields,
  setPreviewDocumentSignatories,
  setIsNewDocument,
  setDocuments,
  handleStepFieldsAdd,
  handleStepsFieldsDelete,
  setIsSubmitAvailable,
  replace,
}) => {
  const { t } = useTranslation();
  const { users } = useUsersState();

  const handleDocumentAdd = async (documentId: number) => {
    const documentData = await getDocumentTemplateById(documentId);
    const documentWithAdditionalFields = {
      ...documentData,
      fields: documentData?.fields?.map(field => ({
        ...field,
        processFieldId: '',
        backUniqueId: '',
      })),
      uniqueId: uuid(),
      backUniqueId: '',
      firstAppearanceStep: currentProcessIndex + 1,
      signatureSteps: [],
      hiddenSteps: [],
    };
    setDocuments(docs => [...docs, documentWithAdditionalFields]);
    setFocusedDocumentId(documentWithAdditionalFields.uniqueId);
    setIsNewDocument(true);
    setIsSubmitAvailable(true);
  };

  const handleDocumentVisibilityChange = (documentId, isVisible) => {
    const modifiedDocumentIndex = documents.findIndex(doc => doc.uniqueId === documentId);
    const modifiedDocument = documents[modifiedDocumentIndex];
    if (isVisible) {
      modifiedDocument.hiddenSteps = modifiedDocument.hiddenSteps.filter(v => v !== currentProcessIndex + 1);
    } else {
      modifiedDocument.hiddenSteps = [...modifiedDocument.hiddenSteps, currentProcessIndex + 1].sort((a, b) => a - b);
      modifiedDocument.signatureSteps = modifiedDocument.signatureSteps.filter(v => v !== currentProcessIndex + 1);
    }
    setDocuments(docs => docs.map((doc, index) => index === modifiedDocumentIndex ? modifiedDocument : doc));
    console.log('finish modifiedDocument', modifiedDocument);
  };

  const handleDocumentFieldsAdd = async (addedFields: DocumentTemplateField[]) => {
    const currentStepFields = fields[currentProcessIndex].fields;
    const currentDocumentIndex = documents.findIndex(doc => doc.uniqueId === focusedDocumentId);
    const currentDocument = documents[currentDocumentIndex];
    const newFieldsComponents = addedFields.map(documentField => documentField.type);
    const newFieldsHints = addedFields.map(documentField => documentField.name);
    const newFieldsPlaceholders = addedFields.map(documentField => documentField.hint || '');
    const newFieldsIds = handleStepFieldsAdd(newFieldsComponents, currentStepFields.length + 1, newFieldsHints, newFieldsPlaceholders, focusedDocumentId, false);
    for (let i = 0; i < addedFields.length; i++) {
      const fieldIndex = currentDocument.fields.findIndex(field => field.id === addedFields[i].id);
      currentDocument.fields[fieldIndex].processFieldId = newFieldsIds[i];
    }

    setIsSubmitAvailable(true);
    setDocuments(docs => docs.map((doc, index) => index === currentDocumentIndex ? currentDocument : doc));
  };

  const handleDocumentParamsChange = (modifiedDocument: DocumentTemplate) => {
    const currentDocumentIndex = documents.findIndex(doc => doc.uniqueId === focusedDocumentId);
    setDocuments(docs => docs.map((doc, index) => index === currentDocumentIndex ? modifiedDocument : doc));
    setIsSubmitAvailable(true);
  };

  const handleDocumentChangeUndo = (addedFields: string[], linkedFields: string[], hasSignatureStateChanged: boolean) => {
    const currentDocumentIndex = documents.findIndex(doc => doc.uniqueId === focusedDocumentId);
    const currentDocument = documents[currentDocumentIndex];

    // remove added fields
    if (addedFields.length) {
      const addedFieldsProcessFieldsIds = addedFields.map(addedFieldId => currentDocument.fields.find(field => field.id === addedFieldId).processFieldId);
      handleStepsFieldsDelete(addedFieldsProcessFieldsIds);
    }

    // remove links to added or linked fields from document
    currentDocument.fields = currentDocument.fields.map(field => ({
      ...field,
      processFieldId: [...addedFields, ...linkedFields].includes(field.id) ? '' : field.processFieldId,
    }));

    // restore initial signature state
    if (hasSignatureStateChanged) {
      const currentSignatureState = currentDocument.signatureSteps.includes(currentProcessIndex + 1);
      currentDocument.signatureSteps =
        currentSignatureState
        ? currentDocument.signatureSteps.filter(step => step !== currentProcessIndex + 1)
        : [...currentDocument.signatureSteps, currentProcessIndex + 1].sort((a, b) => a - b);
    }

    // delete document if new document was created
    // otherwise update existing document
    if (isNewDocument) {
      setDocuments(docs => docs.filter(doc => doc.uniqueId !== focusedDocumentId));
    } else {
      setDocuments(docs => docs.map((doc, index) => index === currentDocumentIndex ? currentDocument : doc));
    }
    setFocusedDocumentId('');
  };

  const handleDocumentDelete = (documentId: string) => {
    // remove fields added from this document template
    const modifiedProcessFields = fields.map(processField => {
      return {
        ...processField,
        fields: processField.fields.filter(stepField =>
          stepField.documentId !== documentId,
        ),
      };
    });
    replace(modifiedProcessFields);

    setDocuments(docs => docs.filter(doc => doc.uniqueId !== documentId));

    if (focusedDocumentId === documentId) {
      setFocusedDocumentId('');
    }
  };

  const handleDocumentPreviewOpen = (documentId, documentData = null) => {
    const document = documentData || documents?.find(doc => doc.id === documentId);
    if (!document) {
      return;
    }
    setPreviewedDocumentTemplateId(documentId);
    const directoriesNodes = allDirectories ? initializeSelectedStates(allDirectories, [], false) : {};
    const documentDepartment = directoriesNodes[document.department]?.data?.localization[i18n.language] || directoriesNodes[document.department]?.data?.value || document.department;
    const documentType = directoriesNodes[document.type]?.data?.localization[i18n.language] || directoriesNodes[document.type]?.data?.value || document.type;
    const toolbarFields = [
      {
        title: t('customProcesses.documentPreview.toolbar.department', { defaultValue: 'Department' }),
        value: documentDepartment || '-',
      },
      {
        title: t('customProcesses.documentPreview.toolbar.type', { defaultValue: 'Type' }),
        value: documentType || '-',
      },
      {
        title: t('customProcesses.documentPreview.toolbar.signatureType', { defaultValue: 'Signature type' }),
        value: 'eGov',
      },
    ];
    setPreviewDocumentToolbarFields(toolbarFields);

    const documentSignatories = [];
    document?.signatureSteps?.forEach((signatureStep) => {
      if (signatureStep === 1) {
        documentSignatories.push(
          {
            value: t('customProcesses.documentPreview.signatories.initiator'),
            stepOrder: '1',
          },
        );
      } else {
        const step = fields[signatureStep - 1];
        const stepAssignees = step.assigneeId;
        if (stepAssignees.length > 0) {
          const firstAssignee = users[stepAssignees[0]];
          if (firstAssignee) {
            const stepAssigneesDepartment = firstAssignee.department;
            const departmentGlossaryNode = directoriesNodes[stepAssigneesDepartment];
            const departmentName = departmentGlossaryNode?.data?.localization[i18n.language] || departmentGlossaryNode?.data?.value || stepAssigneesDepartment || t('form_components.select_users.no_department_name');
            documentSignatories.push({
              value: departmentName as string,
              stepOrder: getDisplayedStepOrder(step, conditions, parallelBranchesGroups),
            });
          }
        }
      }
    });
    setPreviewDocumentSignatories(documentSignatories);
  };

  const handleDocumentPreviewClose = () => {
    setPreviewedDocumentTemplateId(-1);
    setPreviewDocumentToolbarFields([]);
    setPreviewDocumentSignatories([]);
  };

  return {
    handleDocumentAdd,
    handleDocumentVisibilityChange,
    handleDocumentFieldsAdd,
    handleDocumentParamsChange,
    handleDocumentChangeUndo,
    handleDocumentDelete,
    handleDocumentPreviewOpen,
    handleDocumentPreviewClose,
  };
};
