export const matomoScript = `
  var profile = JSON.parse(localStorage.getItem('profile') || 'null');
  var _paq = window._paq = window._paq || [];
  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  _paq.push(["setDomains", [".darlean.com",".app.darlean.com",".app.darlean.eu",".app.darlean.kz",".darlean.eu","*.darlean.kz", "dms.dar-qa.zone"]]);
  _paq.push(["enableCrossDomainLinking"]);
  _paq.push(['trackPageView']);
  _paq.push(['enableLinkTracking']);
  _paq.push(['setUserId', profile?.id]);
  (function() {
    var u="https://5qit.matomo.cloud/";
    _paq.push(['setTrackerUrl', u+'matomo.php']);
    _paq.push(['setSiteId', '1']);
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src='https://cdn.matomo.cloud/5qit.matomo.cloud/matomo.js'; s.parentNode.insertBefore(g,s);
  })();
`;

export const matomoTagManagerScript = `
  var _mtm = window._mtm = window._mtm || [];
  _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
  (function() {
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src='https://cdn.matomo.cloud/5qit.matomo.cloud/container_eJ0Zm0IU.js'; s.parentNode.insertBefore(g,s);
  })();
`;

interface Event {
  category: string;
  action: string;
  name: string;
  payload?: Object;
}
export const sendCustomEvent = (category: string, action: string, name: string, payload?: Object) => {
  window._paq?.push(['trackEvent', category, action, name, payload]);
}
