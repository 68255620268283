import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(({ palette, spacing }) => ({
  root: {
    width: "100%",
  },
  dropdownPaper: {
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.12) !important'
  },
  dropdownRoot: {
    zIndex: 1300
  },
  title: {
    margin: '0 0 6px 0',

    color: '#6D6E85',
    lineHeight: '18px',
    fontSize: 14,
    fontWeight: 600
  },
  icon: {
    paddingLeft: 12,
    paddingRight: 8,
    boxSizing: 'content-box',

    '& img, & svg': {
      width: 24,
      height: 24,
    }
  },
  checkbox: {
    marginLeft: 0,
    marginRight: spacing(1),
    '&:hover': {
      backgroundColor: '#F5F8FA !important'
    },
    '& svg path': {
      fill: '#007994 !important'
    },
  },
  checkboxChecked: {
    '& svg path': {
      fill: '#007994 !important'
    }
  },
  checkboxDisabled: {
    '& svg path': {
      fill: palette.grey[300] + ' !important'
    }
  },
  selectedLabelText: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,

    '& b': {
      marginLeft: 5
    }
  },
  select: {
    padding: '8px !important',
    borderRadius: 8,
    height: 40,
    '& .MuiSelect-select': {
      paddingLeft: 8,
    },

    "& :focus": {
      background: 'none'
    }
  },
  selectedIcon: {
    display: 'flex',

    '& img, & svg': {
      width: 24,
      height: 24,
    }
  },
  menuItem: {
    marginLeft: 0
  },
  selectedMenuItem: {
    background: 'rgba(38, 40, 66, 0.08)'
  },
  selectContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  selectOptionWrapper: {
    maxHeight: 213,
    overflowY: 'scroll'
  },
  searchWrapper: {
    padding: '8px 9px'
  },
  searchInput: {
    width: '100%',
    height: '32px !important',

    '& input': {
      padding: 0
    },

    '& > div': {
      borderRadius: 10,
      height: '32px !important',
      marginTop: 4
    }
  },
  footer: {
    height: 48,
    padding: 15,
    borderTop: '1px solid rgba(38, 40, 66, 0.08)',
  },
  searchIconWrapper: {
    marginRight: 3,

    '& svg': {
      fill: '#8B8C9E',
      width: 17
    }
  },
  linkButton: {
    marginLeft: 15,

    color: '#007994',
    fontWeight: 600,
    fontSize: 14,
  },
  dropdownIndicatorIcon: {
    marginRight: 4
  },
  dropdownDownIndicatorIcon: {
    rigth: 7,
    color: '#CED7DF',
    fill: '#CED7DF'
  }
}));
