import React, { ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  Box,
  IconButton,
  Link,
  TableCell,
  Typography,
  ButtonBase,
  TextField,
  InputAdornment,
  Tooltip,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import cn from 'classnames';
import moment from 'moment';
import debounce from 'lodash/debounce';
import compact from 'lodash/compact';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { UpgradeModal } from '@dar-dms/utils';
import classNames from 'classnames';

import { useUsersState } from 'store/users';
import { useUserProfile, useUsersRole } from 'hooks';
import { environment } from 'environments/environment'
import { sendCustomEvent } from 'utils/analytics'


import {
  ArchiveProcessDialog,
  CreateSettingsCongratulationDialog,
  CreateTemplateProcessesDialog,
  SetupTemplateProcessesDialog,
  TemplateProcessesEmptyContent,
  TemplateProcessesTable,
  TemplateListDialog,
  SaveAsTemplateDialog,
} from './components';
import { CustomPagination, ErrorMessage, MainTabs, Spinner, UserInfo, NewButtonRequestHeader } from 'components';
import Filter from './components/Filter/Filter';
import { DeleteProcessDialog } from './components/DeleteProcessDialog';
import { OpenProcessConfirmationDialog } from './components/OpenProcessConfirmationDialog';
import { DuplicateProcessDialog } from './components/DuplicateProcessDialog';
import { CopyProcessDialog } from './components/CopyProcessDialog';
import ProcessContext from '../../contexts/ProcessContext';
import { RequestsListLayout } from '../../layouts/RequestsListLayout';

import { useTemplateProcesses } from './useTemplateProcesses';

import { ProcessContextType, StatusType } from 'types';
import {
  TemplateProcess,
  TemplateProcessTableColumn,
  TemplateProcessTableMenuButton,
} from './TemplateProcesses.types';

import useTemplateProcessesStyles from './TemplateProcesses.useStyles';
import FilterIcon from 'assets/images/icons/new-filter-icon.svg';
import AutoStartOnIcon from 'assets/images/icons/autostart-on.svg';
import AutoStartPauseIcon from 'assets/images/icons/autostart-pause.svg';
import AutoStartFinishedIcon from 'assets/images/icons/autostart-finished.svg';
import AutoStartSettingsButtonIcon from 'assets/images/icons/settings-icon-light.svg';
import DropdownIndicator from 'assets/images/icons/dropdown-indicator.svg';
import WarningIcon from 'assets/images/icons/warning-sign.svg';
import MenuEditIcon from 'assets/images/icons/template_processes_menu_edit_icon.svg';
import MenuCopyIcon from 'assets/images/icons/template_processes_menu_copy_icon.svg';
import MenuArchiveIcon from 'assets/images/icons/template_processes_menu_archive_icon.svg';
import MenuDeleteIcon from 'assets/images/icons/template_processes_menu_delete_icon.svg';
import MenuSaveAsTemplateIcon from 'assets/images/icons/document_regular.svg';
import UpgradeModalImageEn from 'assets/images/upgrade-images/upgrade-modal-image-en.svg'
import UpgradeModalImageRu from 'assets/images/upgrade-images/upgrade-modal-image-ru.svg'
import UpgradeModalImageKz from 'assets/images/upgrade-images/upgrade-modal-image-kz.svg'
import UpgradeModalImageId from 'assets/images/upgrade-images/upgrade-modal-image-id.svg'
import UpgradeModalImageDe from 'assets/images/upgrade-images/upgrade-modal-image-de.svg'
import Search from '@mui/icons-material/Search';

const UpgradeModalImages = {
  'en': UpgradeModalImageEn,
  'ru': UpgradeModalImageRu,
  'kz': UpgradeModalImageKz,
  'id': UpgradeModalImageId,
  'de': UpgradeModalImageDe,
}

export type TypeFilterValues = {
  searchText: string;
  group: any[];
  author: any[];
  published?: boolean;
  autoStart?: boolean;
  createdDate?: string;
  lastUpdated?: string;
  initiator?: any[];
}

type PaginationType = {
  page: number;
  pageSize: number;
}

const FILTER_INITIAL_VALUE: TypeFilterValues = {
  searchText: '',
  group: [],
  author: [],
  initiator: [],
};

export function getSortString(sortArray): string {
  return `${sortArray[0]}, ${sortArray[1]}`;
}



export const defaultSort = ['lastUpdated', 'DESC'];

const TemplateProcesses = (): ReactElement => {
    const location = useLocation();
    const currentRegion = environment.region === 'US' ? 'MM/DD/YYYY' : 'DD.MM.YYYY';
    const history = useHistory();
    const { i18n, t } = useTranslation();
    const { companyId } = useUserProfile();
    const { id: processId } = useParams<{ id?: string }>();

    const classes = useTemplateProcessesStyles();
    const { users = [] } = useUsersState();
    const { groups }: Partial<ProcessContextType> = useContext(ProcessContext);

    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [searchFieldText, setSearchFieldText] = useState('');
    const [filterValue, setFilterValue] = useState<TypeFilterValues>(FILTER_INITIAL_VALUE);
    const [pagination, setPagination] = useState<PaginationType>({
      page: 0,
      pageSize: 25,
    });

    const [sort, setSort] = useState(defaultSort);
    const {
      isTemplateProcessArchivingAvailable,
      isTemplateProcessEditingAvailable,
      isTemplateProcessCopyingAvailable,
      isTemplateProcessCreationAvailable,
      isTemplatePageAvailable,
    } = useUsersRole();

    const debouncedSetSearchText = useCallback(
      debounce((value: string) => setSearchText(value), 400),
      [setSearchText]);

    const handleSearchTextChange = (value: string) => {
      setSearchFieldText(value);
      debouncedSetSearchText(value);
    };

    useEffect(() => {
      setPagination((values) => ({ ...values, page: 0 }));
    }, [filterValue]);

    useEffect(() => {
      if (typeof location.state === 'object' && 'openCreateCustomProcessDialog' in location.state) {
        handleTemplatesListDialogOpen();
        history.replace('/template-processes', undefined);
      }
    }, [location.state]);

    const filterParams = useMemo(() => {
      return {
        ...filterValue,
        group: filterValue.group.map(g => g.value),
        author: filterValue.author.map(g => g.value),
        initiator: filterValue.initiator.map(g => g.value),
        ...pagination,
        sort: getSortString(sort),
      };
    }, [pagination, sort]);

    const {
      isCreateTemplateDialogOpen,
      isCongratulationsDialogOpen,
      isSetupTemplateDialogOpen,
      loadingStatus,
      errorMessage,
      anchorElement,
      autostartAnchorElement,
      templateProcesses,
      templateSettings,
      departmentsList,
      availableLocalesList,
      currentRow,
      currentProcess,
      currentProcessPublishedVersion,
      areValuesChanged,
      needResetProcessForm,
      setNeedResetProcessForm,
      setValuesChanged,
      setTemplateSettings,
      getSettingsProcessCompany,
      handleAnchorReset,
      handleAutostartAnchorReset,
      handleActionsClick,
      handleAutostartActionsClick,
      handleProcessNameClick,
      handleProcessEditClick,
      onCreateProcessDialogClose,
      handleCongratulationsDialogClose,
      handleSetupTemplateDialogClose,
      handleTemplateProcessesDialogSubmit,
      handleTemplateProcessActivate,
      handleTemplateProcessDeactivate,
      handleTemplateProcessDelete,
      handleAutostartStart,
      handleAutostartStop,
      handleNewTemplateProcessDialogOpen,
      handleBackButtonClick,
      isArchiveConfirmationDialogOpen,
      handleArchiveConfirmationDialogOpen,
      handleArchiveConfirmationDialogClose,
      isPublishedProcessesLimitDialogOpen,
      handlePublishedProcessesLimitDialogOpen,
      handlePublishedProcessesLimitDialogClose,
      isProcessDeleteConfirmationDialogOpen,
      handleProcessDeleteConfirmationDialogOpen,
      handleProcessDeleteConfirmationDialogClose,
      isCopyProcessDialogOpen,
      handleCopyProcessDialogOpen,
      handleCopyProcessDialogClose,
      isDuplicateProcessDialogOpen,
      handleDuplicateProcessDialogClose,
      isConfirmProcessCopyOpenDialogOpen,
      handleConfirmProcessCopyOpenDialogOpen,
      handleConfirmProcessCopyOpenDialogClose,
      processCopyParams,
      setProcessCopyParams,
      onCopyProcessDialogOpen,
      handleProcessCopy,
      totalPages,
      totalElements,
      loading,
      onDuplicateProcessDialogOpen,
      isTemplatesListDialogOpen,
      handleTemplatesListDialogOpen,
      handleTemplatesListDialogClose,
      handleSaveAsTemplate,
      isSaveAsTemplateDialogOpen,
      onSaveAsTemplateOpen,
      handleSaveAsTemplateDialogClose,
    } = useTemplateProcesses(filterParams, processId);

    const menuButtons = useMemo((): TemplateProcessTableMenuButton[] =>
        currentRow ?
        [
          {
            title: t('customProcesses.creationPage.buttons.editProcess'),
            onClick: handleProcessEditClick,
            visible: isTemplateProcessEditingAvailable,
            icon: MenuEditIcon,
          },
          {
            title: t('customProcesses.table.menu.activation'),
            isProcessPublished: false,
            onClick: handleTemplateProcessActivate,
            visible: false,
          },
          {
            title: t('customProcesses.creationPage.buttons.archiveProcess'),
            isProcessPublished: true,
            onClick: handleArchiveConfirmationDialogOpen,
            visible: isTemplateProcessArchivingAvailable,
            icon: MenuArchiveIcon,
          },
          {
            title: t('customProcesses.creationPage.buttons.deleteProcess'),
            isProcessPublished: false,
            onClick: handleProcessDeleteConfirmationDialogOpen,
            visible: isTemplateProcessArchivingAvailable,
            icon: MenuDeleteIcon,
          },
          {
            title: t('customProcesses.creationPage.buttons.copyProcess'),
            onClick: () => onCopyProcessDialogOpen(currentRow),
            visible: isTemplateProcessCopyingAvailable,
            icon: MenuCopyIcon,
          },
          {
            title: t('templates.buttons.saveAsTemplate'),
            isProcessPublished: true,
            onClick: () => onSaveAsTemplateOpen(currentRow),
            visible: isTemplatePageAvailable,
            icon: MenuSaveAsTemplateIcon,
          },
        ].filter(({
          visible,
          isProcessPublished,
        }) => visible && (isProcessPublished === currentRow.published || typeof isProcessPublished === 'undefined')) :
          [],
      [currentRow,
       isTemplatePageAvailable,
       isTemplateProcessEditingAvailable,
       isTemplateProcessArchivingAvailable,
       isTemplateProcessCreationAvailable,
       isTemplateProcessCopyingAvailable,
       handleProcessEditClick,
       handleTemplateProcessActivate,
       handleArchiveConfirmationDialogOpen,
       handleProcessDeleteConfirmationDialogOpen,
       handleCopyProcessDialogOpen,
       onCopyProcessDialogOpen,
       isTemplatePageAvailable,
      ]);

    const autostartMenuButtons = useMemo((): TemplateProcessTableMenuButton[] =>
        currentRow ?
        [
          {
            title: t('customProcesses.creationPage.processForm.autostart.status.started'),
            onClick: handleAutostartStart,
            icon: AutoStartOnIcon,
            visible: currentRow?.autoStart !== 'FINISHED',
          },
          {
            title: t('customProcesses.creationPage.processForm.autostart.status.stopped'),
            onClick: handleAutostartStop,
            icon: AutoStartPauseIcon,
            visible: currentRow?.autoStart !== 'FINISHED',
          },
          {
            title: t('customProcesses.creationPage.processForm.autostart.setupAutostart'),
            onClick: handleProcessEditClick,
            visible: isTemplateProcessEditingAvailable && !isSetupTemplateDialogOpen,
            icon: AutoStartSettingsButtonIcon,
          },
        ].filter(({
          visible,
        }) => visible) : [],
      [
        currentRow,
        isTemplateProcessEditingAvailable,
        isSetupTemplateDialogOpen,
        handleProcessEditClick,
      ]);

    useEffect(() => {
      setFilterValue({
        ...filterValue,
        searchText,
      });
    }, [searchText]);

    const usedFilterCountNumber = useMemo(() => {
      const filters = compact(Object.values({ ...filterValue, searchText: null })).filter(el => {
        if (Array.isArray(el) && el.length === 0) return false;
        return true;
      });

      return filters.length;

    }, [filterValue]);

    const renderCell = (column: TemplateProcessTableColumn, row: TemplateProcess): ReactElement => {
      switch (column.id) {
        case 'processName': {
          return (
            <TableCell
              key={column.id}
              className={classes.tableCell}
              width={column.width}
              style={{ width: column.width }}
            >
              <Box className={classes.templateProcessesProcessName} onClick={() => handleProcessNameClick(row)}>
                <Box
                  className={cn(classes.templateProcessesProcessNameHeader, { [classes.templateProcessesProcessNameHeaderWidthWarning]: row.hasAssigneeError })}>
                  {row.hasAssigneeError &&
                    <Tooltip arrow title={t('customProcesses.table.assigneeError')}>
                      <img className={classes.templateProcessesProcessNameHeaderWarningIcon} src={WarningIcon}/>
                    </Tooltip>
                  }
                  <img className={classes.templateProcessesProcessNameHeaderImage} src={row.iconPath}/>
                </Box>

                <div className={classes.templateProcessNameInfo}>
                  <Tooltip title={row[column.id]} placement="top-start">
                    <Link className={classes.templateProcessesProcessNameTitle}>{row[column.id]}
                    </Link>
                  </Tooltip>

                  <Typography className={classes.templateProcessesProcessNameSubtitle}>
                    {t(`groups.${row['category']}.name`, { defaultValue: groups.find(g => g?.sysName === row['category'])?.name })}
                  </Typography>
                </div>
              </Box>
            </TableCell>
          );
        }

        case 'published': {
          const isPublished = row[column.id];

          return (
            <TableCell key={column.id}
                       className={classes.tableCell}
                       width={column.width}
                       style={{ width: column.width }}
            >
              <Box display="flex" alignItems="center">
                <Box
                  className={cn(classes.templateProcessesProcessStatus, { [classes.templateProcessesProcessStatusActive]: isPublished })}></Box>
                <Box display="flex" flexDirection="column">
                  <Typography className={classes.templateProcessesProcessStatusTitle}>
                    {isPublished
                     ? t('customProcesses.table.statusPublished')
                     : t('customProcesses.table.statusNotPublished')}
                  </Typography>

                  {row?.draftIsChange &&
                    (<Typography className={classes.templateProcessesProcessStatusSubtitle}>
                      {t('customProcesses.table.unpublishedChanges')}
                    </Typography>)
                  }
                </Box>
              </Box>
            </TableCell>
          );
        }
        case 'autoStart': {
          const autostart = row.autoStart;
          let text = '-';
          let icon = AutoStartFinishedIcon;

          if (row.published) {
            if (autostart === 'ACTIVE') {
              text = t('customProcesses.creationPage.processForm.autostart.status.started');
              icon = AutoStartOnIcon;
            } else if (autostart === 'PAUSED' || autostart === 'STOPPED') {
              text = t('customProcesses.creationPage.processForm.autostart.status.stopped');
              icon = AutoStartPauseIcon;
            } else if (autostart === 'FINISHED') {
              text = t('customProcesses.creationPage.processForm.autostart.status.finished');
              icon = AutoStartFinishedIcon;
            }
          }

          if (!row.published || autostart === 'INACTIVE') {
            return (
              <TableCell key={column.id}
                         className={classes.tableCell}
                         width={column.width}
                         style={{ width: column.width }}
              >
                <Box display="flex" alignItems="center">
                  <Typography className={classes.templateProcessesProcessStatusTitle}>
                    -
                  </Typography>
                </Box>
              </TableCell>
            );
          }

          return (
            <TableCell key={column.id}
                       className={classes.tableCell}
                       width={column.width}
                       style={{ width: column.width }}
            >
              <Box display="flex" alignItems="center" justifyContent="space-between"
                   onClick={event => handleAutostartActionsClick(event, row)}>
                <Box display="flex" flexDirection="row" alignItems="center">
                  {text && <Box>
                    <img src={icon}/>
                  </Box>
                  }

                  <Typography className={classes.templateProcessesProcessStatusTitle}>
                    {text}
                  </Typography>
                </Box>

                <img src={DropdownIndicator}/>
              </Box>
            </TableCell>
          );
        }
        case'allLanguages': {
          const availableLanguages = row[column.id]?.split(',')?.map(v => v.trim()) || [];
          const allProcessLanguages = availableLanguages?.join(', ') || '';

          return (
            <TableCell key={column.id}
                       className={classes.tableCell}
                       width={column.width}
                       style={{ width: column.width }}
            >
              <Typography>{allProcessLanguages?.toUpperCase()}</Typography>
            </TableCell>
          );
        }
        case 'creator': {
          const creator = users[row.creator];
          if (!creator) return <TableCell
            key={column.id}
            width={column.width}
            style={{ width: column.width }}
          />;

          return (
            <TableCell
              key={column.id}
              width={column.width}
              style={{ width: column.width }}
              className={classNames(classes.avatar, classes.tableCell)}
            >
              <Box display="flex" alignItems="center">
                <UserInfo
                  user={creator}
                  avatarSize={24}
                  nameMarginLeft={12}
                  maxWidth={100}
                />
              </Box>
            </TableCell>
          );
        }
        case'lastUpdated':
        case'createdDate': {
          const currentDate = moment(row[column.id]).format(currentRegion);

          return (
            <TableCell key={column.id}
                       className={classes.tableCell}
                       width={column.width}
                       style={{ width: column.width }}
            >
              <Typography>{currentDate}</Typography>
            </TableCell>
          );
        }
        case'processMenu': {
          return (
            <TableCell
              key={column.id}
              className={classes.tableCell}
              width={column.width}
              style={{ width: column.width }}
            >
              <IconButton
                size="small"
                className={classes.templateProcessesIconButton}
                onClick={event => handleActionsClick(event, row)}>
                <MoreHorizIcon
                  fontSize="small"
                  className={classes.templateProcessesIconButtonIcon}
                  color="secondary"
                />
              </IconButton>
            </TableCell>
          );
        }
        case 'docflowTemplatesCount': {
          return (
            <TableCell key={column.id}
                       className={classes.tableCell}
                       width={column.width}
                       style={{ width: column.width }}
            >
              <Typography>{row[column.id] || '-'}</Typography>
            </TableCell>
          );
        }
        default: {
          return (
            <TableCell key={column.id}
                       className={classes.tableCell}
                       width={column.width}
                       style={{ width: column.width }}
            >
              <Typography>{row[column.id]}</Typography>
            </TableCell>
          );
        }
      }
    };

    const renderTemplateProcesses = useMemo((): ReactElement => {
      switch (loadingStatus) {
        case StatusType.RESOLVED:
          return templateProcesses.length ? (
            <RequestsListLayout
              hasMenuButtons={false}
              footerChild={
                <Box display="flex" justifyContent="end" alignItems="center" gap={6}>

                  <Typography className={classes.tableFooterHint}>
                    <Trans
                      i18nKey="customProcesses.table.pagination"
                      values={{
                        from: pagination.pageSize * pagination.page + 1,
                        to: Math.min(pagination.pageSize * (pagination.page + 1), totalElements),
                        total: totalElements,
                      }}/>
                  </Typography>

                  {totalElements > 10 &&
                    <CustomPagination
                      pageSize={pagination.pageSize}
                      currentPage={pagination.page + 1}
                      handlePageSizeChange={(e) => {
                        setPagination({
                          ...pagination,
                          pageSize: e.target.value,
                          page: 0,
                        });
                      }}
                      handlePageChange={(_, page) => {
                        setPagination({
                          ...pagination,
                          page: page - 1,
                        });
                      }}
                      totalPages={totalPages}
                    />
                  }
                </Box>
              }
            >
              <TemplateProcessesTable
                rows={templateProcesses}
                anchorElement={anchorElement}
                autostartAnchorElement={autostartAnchorElement}
                menuButtons={menuButtons}
                autostartMenuButtons={autostartMenuButtons}
                renderCell={renderCell}
                onMenuClose={handleAnchorReset}
                onAutostartMenuClose={handleAutostartAnchorReset}
                sort={sort}
                setSort={setSort}
              />

            </RequestsListLayout>
          ) : (
                   <TemplateProcessesEmptyContent
                     onProcessCreate={() => handleNewTemplateProcessDialogOpen(true)}
                     isFilterOn={(usedFilterCountNumber > 0 || Boolean(searchText.trim()))}
                   />
                 );
        case StatusType.REJECTED: {
          return (
            <ErrorMessage text={errorMessage}/>
          );
        }
        case StatusType.PENDING:
        default: {
          return (
            <Spinner absolute={false}/>
          );
        }
      }
    }, [t, templateProcesses, anchorElement, autostartAnchorElement, autostartMenuButtons, errorMessage, loadingStatus, sort]);

    return (
      <Box className={classes.templateProcesses}>
        <Box display="flex" alignItems="center" justifyContent="space-between" className={classes.headerWrapper}>
          <Typography className={classes.templateProcessesTitle}>
            {t('Processes.module')}
          </Typography>

          <Box>
            <NewButtonRequestHeader/>
          </Box>
        </Box>

        <Box className={classes.tabWrapper}>
          <MainTabs/>
        </Box>


        {((usedFilterCountNumber > 0 || searchText.trim()) || templateProcesses.length > 0) &&
          <>
            <Box className={classes.filterControls}>
              <TextField
                size="medium"
                className={classes.searchInput}
                value={searchFieldText}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" className={classes.searchIconWrapper}>
                      <Search/>
                    </InputAdornment>
                  ),
                }}
                onChange={event => handleSearchTextChange(event.target.value)}
              />

              <ButtonBase
                className={cn(classes.filterButton, {
                  [classes.filterButtonActive]: Boolean(isFilterVisible) || Boolean(usedFilterCountNumber),
                })}
                onClick={() => {
                  setIsFilterVisible(!isFilterVisible);
                }}
              >
                <img
                  src={FilterIcon}
                  alt={t('filters.title')}
                />
                {t('filters.title')} {usedFilterCountNumber ? `(${usedFilterCountNumber})` : null}
              </ButtonBase>
            </Box>

            <Box className={cn(classes.popup, { [classes.popupClosed]: !isFilterVisible })}>
              <Filter
                value={filterValue}
                setValue={(value, close) => {
                  setFilterValue({
                    searchText,
                    ...value,
                  });

                  if (close) return;

                  setIsFilterVisible(false);
                }}
                close={() => setIsFilterVisible(false)}
              />
            </Box>
          </>
        }

        {renderTemplateProcesses}

        {loading && <Spinner absolute={true}/>}


        <ArchiveProcessDialog
          isOpen={isArchiveConfirmationDialogOpen}
          onClose={handleArchiveConfirmationDialogClose}
          onArchive={handleTemplateProcessDeactivate}
        />

        <DeleteProcessDialog
          isOpen={isProcessDeleteConfirmationDialogOpen}
          onClose={handleProcessDeleteConfirmationDialogClose}
          onDelete={handleTemplateProcessDelete}
        />

        <CreateTemplateProcessesDialog
          initialValues={currentProcess}
          availableLocales={availableLocalesList}
          isOpen={isCreateTemplateDialogOpen}
          onValuesChange={() => setValuesChanged(true)}
          onClose={onCreateProcessDialogClose}
          onSubmit={handleTemplateProcessesDialogSubmit}
        />

        {isTemplatesListDialogOpen && <TemplateListDialog
          close={handleTemplatesListDialogClose}
          handleNewTemplateProcessDialogOpen={() => handleNewTemplateProcessDialogOpen()}
          isCreateTemplateDialogOpen={isCreateTemplateDialogOpen}
          isSetupTemplateDialogOpen={isSetupTemplateDialogOpen}
        />}

        <CreateSettingsCongratulationDialog
          isOpen={isCongratulationsDialogOpen}
          onClose={handleCongratulationsDialogClose}
        />

        <SetupTemplateProcessesDialog
          initialValues={currentProcess}
          publishedVersionValues={currentProcessPublishedVersion}
          departmentsList={departmentsList}
          availableLocales={availableLocalesList}
          isOpen={isSetupTemplateDialogOpen}
          templateSettings={templateSettings}
          haveTemplateSettingsChanged={areValuesChanged}
          onClose={() => {
            handleSetupTemplateDialogClose();
            handleTemplatesListDialogClose();
          }}
          onFormSubmit={getSettingsProcessCompany}
          onBackButtonClick={handleBackButtonClick}
          onProcessArchive={handleTemplateProcessDeactivate}
          onDuplicateProcessDialogOpen={onDuplicateProcessDialogOpen}
          onCopyProcessDialogOpen={onCopyProcessDialogOpen}
          onSaveAsTemplateOpen={onSaveAsTemplateOpen}
          setTemplateSettings={setTemplateSettings}
          needResetProcessForm={needResetProcessForm}
          setNeedResetProcessForm={setNeedResetProcessForm}
          handleAutostartActionsClick={handleAutostartActionsClick}
          handlePublishedProcessesLimitDialogOpen={handlePublishedProcessesLimitDialogOpen}
          handleProcessDeleteConfirmationDialogOpen={handleProcessDeleteConfirmationDialogOpen}
        />

        <UpgradeModal
          open={isPublishedProcessesLimitDialogOpen}
          onClose={handlePublishedProcessesLimitDialogClose}
          heading={t('customProcesses.upgradeModal.heading')}
          description={t('customProcesses.upgradeModal.description')}
          image={<img src={UpgradeModalImages[i18n.language] || UpgradeModalImageEn}/>}
        />

        <DuplicateProcessDialog
          processData={currentRow}
          isOpen={isDuplicateProcessDialogOpen}
          onClose={handleDuplicateProcessDialogClose}
          onConfirm={async (processCopyName, openNewProcess) => {
            return await handleProcessCopy(processCopyName, [companyId], true, 'duplicate', openNewProcess);
          }}
        />

        <CopyProcessDialog
          processData={currentRow}
          isOpen={isCopyProcessDialogOpen}
          onClose={handleCopyProcessDialogClose}
          onCopy={handleProcessCopy}
          onCopyAndOpen={(processCopyName, targetWorkspaceId, targetWorkspaceName) => {
            setProcessCopyParams({
              processCopyName,
              targetWorkspaceId,
              targetWorkspaceName,
            });
            handleCopyProcessDialogClose();
            handleConfirmProcessCopyOpenDialogOpen();
          }}
        />

        <SaveAsTemplateDialog
          processData={currentRow}
          isOpen={isSaveAsTemplateDialogOpen}
          onClose={handleSaveAsTemplateDialogClose}
          onSave={handleSaveAsTemplate}
        />

        <OpenProcessConfirmationDialog
          processCopyParams={processCopyParams}
          isOpen={isConfirmProcessCopyOpenDialogOpen}
          onClose={() => {
            handleConfirmProcessCopyOpenDialogClose();
            setProcessCopyParams({});
          }}
          onConfirm={handleProcessCopy}
        />
      </Box>
    );
  }
;

export default TemplateProcesses;
