import React, { memo, ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import { Box, Button, Checkbox, FormControlLabel, Grid, IconButton, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { SearchProcesses, GroupSelect, UserAvatar, DatePeriodPicker } from 'components';

import usebpmProcess from 'contexts/ProcessContext/useProcessContext';
import useContentFilter from './useContentFilter';
import useStyles from './useStyles';

import { PageType } from 'types';
import { useUsersState } from 'store/users';

import CloseIcon from 'assets/images/icons/close-icon.svg'
import { SearchValueContext } from 'pages';
import { useFiltersState } from 'store/search';
import { sendCustomEvent } from '../../../utils/analytics';
import { useLocation } from 'react-router-dom';

type Props = {
  closeFilter?: () => void;
  setBadgeCountDelta?: (value: number) => void;
  completed?: boolean;
  currentPage: number;
  searchValue?: string;
  isSearchExists?: boolean;
  type?: PageType;
  selectedSubpage?: string
};

export const ContentFilter = memo(({
  closeFilter,
  completed,
  currentPage,
  setBadgeCountDelta = () => {},
  isSearchExists = false,
  type = PageType.REQUESTS,
}: Props): ReactElement => {
  const { value: searchTextValueContext } = useContext(SearchValueContext);
  const filters = useFiltersState();
  const location = useLocation();


  const {
    control,
    watch,
    isReviewedApprovalsPage,
    handleSubmit,
    submitFilter,
    resetForm,
    setKeepLegalEntityValue,
    setValue
  } = useContentFilter({
    completed,
    searchValue: searchTextValueContext,
    currentPage,
    type,
    setBadgeCountDelta,
    closeFilter,
  });

  const [formReset, setFormReset] = useState(false);
  const [startDateFrom, setStartDateFrom] = useState(null);
  const [startDateTo, setStartDateTo] = useState(null);
  const [startDatePeriod, setStartDatePeriod] = useState(null);
  const [groupFieldUpdated, setGroupFieldUpdated] = useState(false);

  useEffect(() => {
    if (formReset) {
      setSelectedProcesses({});
      setSelectedGroups({});
      setStartDatePeriod(null);
      setStartDateFrom(null);
      setStartDateTo(null);
      setFormReset(() => false);
    }
  }, [formReset])

  useEffect(() => {
    if(!startDateFrom) return;
    const fromDate = new Date(startDateFrom);
    setValue('fromDate', fromDate);
  }, [startDateFrom]);

  useEffect(() => {
    if(!startDateTo) return;
    const tillDate = new Date(startDateTo);
    setValue('tillDate', tillDate);
  }, [startDateTo]);


  const [selectedGroups, setSelectedGroups] = useState<
    {
      [key: string]: any[]
    }
    >(null);


  const [selectedProcesses, setSelectedProcesses] = useState<
    {
      [key: string]: any[]
    }
    >(null);

  const [initiator, setInitiator] = useState();
  const [author, setAuthor] = useState();
  const [currentAssignee, setCurrentAssignee] = useState();


  const classes = useStyles();
  const { t } = useTranslation();
  const { groups } = usebpmProcess();
  const { users: usersList } = useUsersState();

  const users = useMemo(() => {
    return Object.values(usersList).map(({ fullName, avatar, id }) => ({
      label: fullName,
      value: id,
      icon: <UserAvatar user={{
        avatar,
        id,
        fullName,
        firstName: '',
        fullNameRu: '',
        lastName: '',
        logName: '',
        phoneNumber: '',
        jobTitle: '',
        email: '',
        role: '',
      }}
        avatarSize={24}
      />
    }));
  }, [usersList]);


  const optionsProcesses: any[] = useMemo(() => {
    return groups.map(group => group.processes).flat().map(({name, processIconPath, processDescription, groupName, processSysName }) => ({
      value: `${groupName}___${name}`,
      label: t(`constructor-${processSysName}.name`, {defaultValue: name}),
      icon: <img src={processIconPath} alt={t(`constructor-${processSysName}.description`, {defaultValue: processDescription})} />,
    }))
  }, [groups]);

  const selectedOptionsProcesses: any[] = useMemo(() => {
    if(!selectedProcesses) return [];

    return Object.keys(selectedProcesses).reduce((acc, value) => {
      const processesValues = selectedProcesses[value].map(process => `${value}___${process}`);
      const processesObjects = processesValues.map(v => optionsProcesses.find(option => option.value === v)).filter(Boolean);
      return [...acc, ...processesObjects];
    }, [])
  }, [selectedProcesses]);

  // useEffect(() => {
  //   if (groupFieldUpdated) {
  //     submitFilter({...filters, processDefinitionName: Object.values({ ...selectedProcesses }).flat()});
  //     setGroupFieldUpdated(!groupFieldUpdated);
  //   }
  // }, [groupFieldUpdated, selectedProcesses])
  if (selectedGroups && typeof selectedGroups === 'object') {
    const keys = Object.keys(selectedGroups);
  } else {
    console.log('selectedGroups is null or undefined');
  }

  const fieldsMap = {
    initiator: 'initiator',
    author: 'author',
    selectedProcesses: 'process',
    selectedGroups: 'group',
    startDateTo: 'start_date',
    currentAssignee: 'current_assignee'
  };

  const fieldsMapName = {
    initiator: 'Initiator',
    author: 'Author',
    selectedProcesses: 'Process',
    selectedGroups: 'Group',
    startDateTo: 'StartDate',
    currentAssignee: 'CurrentAssignee'
  }

  const tabMap = {
    '/approvals/active': 'inbox',
    '/approvals/reviewed': 'sent',
    '/requests/active': 'inbox',
    '/requests/completed': 'completed',
    '/monitoring/active': 'watched',
    '/monitoring/completed': 'watched'
  }

  const tabMapName = {
    '/approvals/active': 'Incoming',
    '/approvals/reviewed': 'Watched',
    '/requests/active': 'Incoming',
    '/requests/completed': 'Completed',
    '/monitoring/active': 'Watched',
    '/monitoring/completed': 'Watched'
  }

  const tabType = {
    approvals: 'Incomes',
    requests: 'Sents'
  }

  function handleEvent({ initiator, author, selectedGroups, selectedProcesses, startDateTo, currentAssignee }) {
    // Собираем все поля в объект
    const fields = { initiator, author, selectedGroups, selectedProcesses, startDateTo, currentAssignee };


    const filledFields = Object.entries(fields).filter(
      ([key, value]) => value !== undefined && value !== null && value !== '' && !(Array.isArray(value) && value.length === 0) &&
        !(typeof value === 'object' && !Array.isArray(value) && Object.keys(value).length === 0)
    );

    if (filledFields.length === 1) {
      const [field, value] = filledFields[0];
      sendCustomEvent(`Requests_${location.pathname.includes('approvals') ? 'Incomes': 'Sent'}`, `apply_filter_by_${fieldsMap[field]}_in_${tabMap[location.pathname]}_tab`, `ApplyFilterBy${fieldsMapName[field]}${tabMapName[location.pathname]}Tab`);
    } else {
      sendCustomEvent(`Requests_${location.pathname.includes('approvals') ? 'Incomes': 'Sent'}`, `Click_filter_in_${tabMap[location.pathname]}_tab`, `ClickFilter${tabMapName[location.pathname]}Tab`);
    }
  }

  return (
    <form onSubmit={handleSubmit(submitFilter)} className={classes.filtersForm}>
      <Box className={classes.filtersHeader}>
        <Typography variant="h2" className={classes.filtersTitle}>{t('filters.title')}</Typography>
        <IconButton onClick={closeFilter} size="large"><img src={CloseIcon} className={classes.filtersCloseButton}/></IconButton>
      </Box>

      <Box className={classes.filtersContent}>

        {!isReviewedApprovalsPage && (
          <Box mb={4} display="flex" flexDirection="row" alignItems="center">
            <Controller
              control={control}
              name="urgent"
              render={({ field: { value, onChange }}) => {
                return (
                  <FormControlLabel
                    id="urgentFilterCheckbox"
                    label={t('filters.filter_urgent')}
                    control={
                      <Checkbox
                        color="default"
                        className={cn(classes.checkbox, {[classes.checkboxChecked]: value})}
                        value={value}
                        checked={value}
                        onChange={(e) => onChange(e.target.checked)}
                      />
                    }
                  />
                )
              }}
            />
          </Box>
        )}

        <Box mb={4}>
          <GroupSelect
            value={selectedGroups ? Object.keys(selectedGroups) : []}
            title={t('groupsSettings.deleteModal.fieldLabel')}
            onChange={(values) => {
              const newGroups = groups.reduce((acc, group) => {
                if(values.some(g => g.value === group.name)) {
                  return [...acc, group]
                }

                return acc;
              }, [])

              setBadgeCountDelta(newGroups.length > 0 ? 1 : 0);

              setSelectedGroups({
                ...newGroups.reduce((acc, g) => ({
                  ...acc,
                  [g.name]: g.processes
                }), {})
              })

              setSelectedProcesses({
                ...selectedProcesses,
                ...newGroups.reduce((acc, g) => ({
                  ...acc,
                  [g.name]: g.processes.map(p => p.name)
                }), {})
              })

              setGroupFieldUpdated(true);
            }}
            options={groups.map(g => ({
              label: t(`groups.${g.sysName}.name`, {defaultValue: g.name}),
              value: g.name,
              icon: <img src={g.iconPath} alt={t(`groups.${g.sysName}.description`, {defaultValue: g.description})} />
            }))}
            multiple
          />
        </Box>

        <Box mb={4}>
          <Controller
            control={control}
            name="processDefinitionName"
            render={({ field: { value, onChange } }) => {
              return <GroupSelect
                value={selectedOptionsProcesses}
                title={t('filters.processes')}
                onChange={(values) => {
                  const newProcesses = values.reduce((acc, v) => {
                    const groupName = (v.value).slice(0, v.value.indexOf('___'));
                    const processName = (v.value).slice(v.value.indexOf('___') + 3, v.value.length);
                    const processes = acc[groupName] || [];
                    return { ...acc, [groupName]: [...processes, processName] };
                  }, {});

                  setSelectedProcesses({ ...newProcesses });

                  if (values.length === 0) {
                    setSelectedGroups({});
                  }

                  const processDefinitionNameResult = Object.values({ ...newProcesses }).flat();

                  onChange(processDefinitionNameResult);
                }}
                options={optionsProcesses}
                multiple
              />;
            }}
          />
        </Box>

        {
          type === 'requests' &&  <Box mb={4}>
            <Controller
              control={control}
              name="assignee"
              render={({ field: { value, onChange }}) => {
                setCurrentAssignee(value)
                return <GroupSelect
                  title={t('filters.current_step_assignee')}
                  options={users}
                  value={value}
                  onChange={values => {
                    onChange(values.map(v => v.value));
                  }}
                  multiple
                  isShownSearch
                />
              }}
            />
          </Box>
        }

        {
          type === 'approvals' &&  <Box mb={4}>
            <Controller
              control={control}
              name="author"
              render={({ field: { value, onChange }}) => {
                setAuthor(value);
                // @ts-ignore
                return <GroupSelect
                  title={t('filters.author')}
                  options={users}
                  value={value}
                  onChange={values => {
                    onChange(values.map(v => v.value));
                  }}
                  multiple
                  isShownSearch
                />
              }}
            />
          </Box>
        }

        {
          type === 'approvals' &&  <Box mb={4}>
            <Controller
              control={control}
              name="initiator"
              render={({ field: { value, onChange }}) => {
                setInitiator(value)
                return <GroupSelect
                  title={t('customProcesses.creationPage.processForm.initiator')}
                  options={users}
                  value={value}
                  onChange={values => {
                    onChange(values.map(v => v.value));
                  }}
                  multiple
                  isShownSearch
                />;
              }}
            />
          </Box>
        }

        {isSearchExists && (
          <Box mb={4}>
            <Controller
              control={control}
              name="searchText"
              render={({ field: { value, onChange }}) => (
                <SearchProcesses
                  value={value}
                  label={t("form_components.select.search_placeholder")}
                  onTextChange={onChange}
                />
              )}
            />
          </Box>
        )}

        <Box>
          <DatePeriodPicker
            className={classes.datePicker}
            label={<p className={classes.label}>{t('Processes.request.filter.startDate')}</p>}
            dateFromValue={startDateFrom}
            dateToValue={startDateTo}
            onDateFromChange={setStartDateFrom}
            onDateToChange={setStartDateTo}
            selectedDatePeriod={startDatePeriod}
            onSelectedDatePeriodChange={setStartDatePeriod}
            hideCustomDaysTo={true}
            mainFiltersVariant
            preventInitialValuesSet
          />
        </Box>
      </Box>

      <Box display="flex" justifyContent="flex-end" className={classes.filtersFooter}>
        <Button
          color="secondary"
          onClick={handleSubmit(resetForm)}
          onMouseDown={() => setFormReset(() => true)}
          className={classes.footerButton}>
          {t('customProcesses.filters.clearAll')}
        </Button>

        <Button type="submit" onClick={() => handleEvent({ initiator, author, selectedGroups, selectedProcesses, startDateTo: startDatePeriod, currentAssignee })} className={classes.footerButton}>
          {t('filters.filter_button_apply')}
        </Button>
      </Box>
    </form>
  );
});
