import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { toggleApprovalPanel } from 'store/approvals';
import { useRequestListState } from 'store/requests';
import { useRequestParams } from 'hooks';


import {
  ContentActionsHookProps,
  ContentActionsHookResponse
} from './ContentActions.types';
import { useHistory, useLocation } from 'react-router-dom';
import { sendCustomEvent } from 'utils/analytics'

interface TabMapEntry {
  action: string;
  name: string;
  category: string;
}

type TabMap = Record<string, TabMapEntry>;
const tabMap: TabMap = {
  '/monitoring/active': {
    action: 'click_view_watched_requests_active',
    name: 'ViewWatchedRequestsActive',
    category: 'Requests_Watched'
  },
  '/monitoring/completed': {
    action: 'click_view_watched_requests_completed',
    category: 'Requests_Watched',
    name: 'ViewWatchedRequestsCompleted'
  },
  '/approvals/active': {
    action: 'click_view_inbox_requests_active',
    category: 'Requests_Incomes',
    name: 'ViewIncomingRequestsActive',
  },
  '/approvals/reviewed': {
    action: 'click_view_inbox_requests_reviewed',
    category: 'Requests_Incomes',
    name: 'ViewIncomingRequestsReviewed',
  },
  '/requests/active': {
    action: 'click_view_sent_requests_active',
    category: 'Requests_Sent',
    name: 'ViewSentRequestsActive'
  },
  '/requests/completed': {
    action: 'click_view_sent_requests_completed',
    category: 'Requests_Sent',
    name: 'ViewSentRequestsCompleted'
  },
  '/requests/drafts': {
    action: 'click_view_drafts_in_sent_tab',
    category: 'Requests_Sent',
    name: 'ViewDraftSentTab'
  },
}

export const useContentActions = ({
  type,
  badgeCountDelta = 0,
  basePageUrl = ''
}: ContentActionsHookProps): ContentActionsHookResponse => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const { completed, currentPage } = useRequestListState();
  const { urlParams } = useRequestParams({
    completed,
    currentPage
  });

  const [isFiltersOpen, setOpenFilters] = useState<boolean>(false);
  const [selectedSubpage, setSelectedSubpage] = useState<string>('');

  useEffect(() => {
    const currentSubpageValue = location.pathname.replace(basePageUrl, '');
    setSelectedSubpage(currentSubpageValue);
  }, [location.pathname, basePageUrl]);

  const contentActionBadgeContent = useMemo(
    (): number =>
      !isFiltersOpen
        ? Math.max(0, Object.keys(urlParams).length + badgeCountDelta)
        : 0,
    [isFiltersOpen, badgeCountDelta, urlParams]
  );

  const handleTogglePanel = (): void => {
    dispatch(toggleApprovalPanel());
  };

  const handleFiltersClick = (): void => {
    setOpenFilters(!isFiltersOpen);
  };

  const handleFiltersClose = (): void => {
    setOpenFilters(false);
  };

  const handleSubpageSelect = (value: string): void => {
    sendCustomEvent(tabMap[location.pathname].category, tabMap[location.pathname].action, tabMap[location.pathname].name)
    setSelectedSubpage(value);
    const newPageAddress = basePageUrl + value;
    history.push(newPageAddress);
  };

  return {
    selectedSubpage,
    isFiltersOpen,
    contentActionBadgeContent,
    handleTogglePanel,
    handleFiltersClick,
    handleFiltersClose,
    handleSubpageSelect
  };
};
