import React, {
  useCallback,
  useEffect,
  useMemo,
  createContext,
  useState,
  Dispatch,
  useContext,
  useRef
} from 'react';
import { useDispatch } from 'react-redux';
import { Typography, Box, Fade } from '@mui/material';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { NotificationManager } from 'react-notifications';

import {
  useCreateProcessState,
  useSavedDraftStatus,
  useProcessDialogState,
  openCreateDialogAction as openDialog,
  setCreateRequestAction as setCreate,
  clearTaskData,
  useTaskState,
  useRequestActiveStep,
  useTemplatesState,
  setSubmitSnackbarParams,
  createAfterSubmitAction,
  setCurrentRequestProcessName, setCurrentRequestDefinitionId, useCreateAfterSubmit
} from 'store/requests';

import { NewRequest } from 'pages/Requests/NewRequest';

import DoneIcon from 'assets/images/icons/done.svg';
import useStyles from './useStyles';
import { CloseButton } from 'components';
import { ProcessContextType } from 'types';
import ProcessContext from 'contexts/ProcessContext';
import { ProcessStartForm } from '@dar-dms/utils';

export const AbortControllerContext = createContext<{
  setAbortController: Dispatch<React.SetStateAction<AbortController>>
}>(null);

export const CreateProcessDialog = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [abortController, setAbortController] = useState<AbortController | null>(null);

  const { groups }: Partial<ProcessContextType> = useContext(ProcessContext);

  const dispatch = useDispatch();
  const createProcess = useCreateProcessState();
  const draftSaved = useSavedDraftStatus();
  const open = useProcessDialogState();
  const createNewTaskAfterSubmit = useCreateAfterSubmit();

  const { data: bpmTask } = useTaskState();
  const { taskTemplate } = useTemplatesState();
  const { isSummaryStep } = useRequestActiveStep();

  const onTaskComplete = (action) => {
    dispatch(
      setSubmitSnackbarParams({
        open: true,
        action: action,
      })
    );
    if (!createNewTaskAfterSubmit) {
      onClose();
    }
  }

  const onClose = useCallback(() => {
    if (abortController) {
      abortController.abort();
    }
    dispatch(openDialog(false));
    dispatch(clearTaskData());

    setTimeout(() => {
      dispatch(setCreate(null));
    }, 300);
  }, [dispatch, abortController, createProcess]);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      setTimeout(() => {
        document.body.style.overflow = null;
      }, 300);
    }
  }, [open, dispatch]);

  const localizedProcessName = useMemo(() => {
    const processNameKey = `constructor-${createProcess?.sysName}.name`;
    return t(processNameKey, { defaultValue: createProcess?.name || '' });
  }, [createProcess]);

  const currentTaskGroup = useMemo(() => {
    if (!bpmTask) return null;
    return groups.find(g => g?.sysName === bpmTask?.businessTask?.groupSysName);
  }, [bpmTask, groups]);

  return (
    <Box component="dialog" className={cn(classes.dialog, { [classes.open]: open })}>
      {!createProcess && <Box
        component="header"
        className={classes.header}
        display="flex"
        justifyContent="space-between"
        alignItems="center">
        <Box display="flex" justifyContent="center" flexDirection="column" gap={6}>
          {currentTaskGroup && <Box className={classes.groupName}>
            <img src={currentTaskGroup.iconPath} alt="" />
            <span>{t(`groups.${currentTaskGroup.sysName}.name`, { defaultValue: currentTaskGroup?.name })}</span>
          </Box>}
          <Typography variant="h3" className={classes.title}>
            {isSummaryStep
              ? t('new_request.title_request_start_summary')
              : `${t('new_request.title_request_start_prefix')} ${localizedProcessName || ''} ${t('new_request.title_request_start_suffix')}`}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" gap={12}>
          {createProcess && bpmTask && (
            <Fade in={draftSaved} timeout={300}>
              <Typography className={classes.draftStatus} variant="caption">
                <img src={DoneIcon} alt="Done" /> {t('customProcesses.creationPage.auto-saved')}
              </Typography>
            </Fade>
          )}

          <CloseButton onClick={onClose} />
        </Box>
      </Box>
      }

      {createProcess ? (
        <ProcessStartForm
          id={createProcess?.id}
          idType="taskId"
          isNewRequest={createProcess.isNewRequest}
          taskTemplate={taskTemplate}
          handleClose={onClose}
          currentTaskGroup={currentTaskGroup}
          localizedProcessName={localizedProcessName}
          onDialogClose={onClose}
          onTaskComplete={onTaskComplete}
          onTaskLoadError={() => NotificationManager.error(t('errors.somethingIsWrong'))}
          shouldShowSuccessNotification={false}
          onSubmitAndCreateAnother={(processName, processDefinitionId) => {
            dispatch(createAfterSubmitAction(true));
            dispatch(setCurrentRequestProcessName(processName));
            dispatch(setCurrentRequestDefinitionId(processDefinitionId));
          }}
        />
      ) : (
        <Box component="article" className={classes.content}>
          <AbortControllerContext.Provider value={{
            setAbortController
          }}>
            <NewRequest />
          </AbortControllerContext.Provider>
        </Box>
      )}
    </Box>
  );
};
