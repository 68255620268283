import React from 'react'
import FlowUseStyles from './Flow.useStyles'
import { HistoryTab } from '../HistoryTab';
import { useTaskState } from 'store/requests';

export const Flow = () => {
  const classes = FlowUseStyles();
  const { data, loading } = useTaskState();

  return <div
    className={classes.root}
  >
    {
      !loading
      && data
      && <HistoryTab
        companyId={data?.businessTask?.companyId}
        activeTab='history'
      />
    }
</div>
}
