import React, {ReactElement} from "react";
import {Box, Button, Dialog, DialogActions, DialogContent, IconButton, Typography} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

import useChangesRollbackConfirmationDialogStyles from './ChangesRollbackConfirmationDialog.useStyles';
import { sendCustomEvent } from '../../../../utils/analytics';
import { useLocation } from 'react-router-dom';

interface ChangesRollbackConfirmationDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const ChangesRollbackConfirmationDialog = ({ isOpen, onClose, onConfirm }: ChangesRollbackConfirmationDialogProps): ReactElement => {
  const { t } = useTranslation();
  const classes = useChangesRollbackConfirmationDialogStyles();
  const location = useLocation();

  const handleConfirm = () => {
    // @ts-ignore
    if(location?.state?.fromButton === 'editButton'){
      sendCustomEvent('Requests_Custom_Processes', 'Delete_changes_in_process_from_3_points', 'DeleteChangesInProcessFrom3Points');
    } else {
      sendCustomEvent('Requests_Custom_Processes','Delete_changes_in_process_from_click_process',  'DeleteChangesInProcessFromClickProcess');
    }
    onConfirm();
    onClose();
  }

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <Box px={6} pt={6} display="flex" alignItems="center" justifyContent="space-between">
        <Typography className={classes.dialogTitle}>
          {t('customProcesses.creationPage.processForm.changesRollbackDialogTitle')}
        </Typography>
        <IconButton onClick={onClose} size="large">
          <CloseIcon className={classes.dialogIcon} />
        </IconButton>
      </Box>

      <DialogContent>
        <Typography className={classes.dialogSubtitle}>
          {t('customProcesses.creationPage.processForm.changesRollbackDialogMessage')}
        </Typography>
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <Button variant="contained" color="secondary" onClick={onClose}>
          {t('template_delete_modal.button_cancel')}
        </Button>
        <Button variant="contained" color="primary" onClick={handleConfirm}>
          {t('customProcesses.table.actions.delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
