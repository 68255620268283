import { useSelector } from 'react-redux';

import type { RootState } from 'store';

export const useCreateProcessState = () =>
  useSelector((state: RootState) => state.requests.createProcess);

export const useProcessIconList = () =>
  useSelector((state: RootState) => state.requests.processIcons)

export const useProcessDialogState = () =>
  useSelector(({ requests: { createDialogOpen } }: RootState) => createDialogOpen);

export const useDarkOverlayState = () =>
  useSelector(({ requests: { darkOverlayShown } }: RootState) => darkOverlayShown);

export const useSubmitSnackbarState = () =>
  useSelector((state: RootState) => state.requests.submitSnackbar);

export const useProcessesListState = () =>
  useSelector((state: RootState) => state.requests.processes);

export const useAvailableTemplateProcessesListState = () =>
  useSelector((state: RootState) => state.requests.availableTemplateProcesses);

export const useTemplatesState = () =>
  useSelector((state: RootState) => state.requests.templates);

export const useRequestListState = () =>
  useSelector((state: RootState) => state.requests.requestsList);

export const useGlossaryState = () =>
  useSelector((state: RootState) => state.requests.glossary);

export const useRequestActiveStep = () =>
  useSelector(({ requests: { activeStep, isSummaryStep } }: RootState) =>
    ({ activeStep, isSummaryStep }));

export const useSkippedTaskSteps = () =>
  useSelector((state: RootState) =>
    ({ skippedSteps: state.requests.skippedSteps }));

export const useSavedDraftStatus = () =>
  useSelector((state: RootState) => state.requests.draftSaved);

export const useDraftsListNeedRefreshStatus = () =>
  useSelector((state: RootState) => state.requests.refreshDraftsList);

export const useCreateAfterSubmit = () =>
  useSelector((state: RootState) => state.requests.createAfterSubmit);

export const useCurrentRequestProcessName = () =>
  useSelector((state: RootState) => state.requests.currentRequestProcessName);

export const useCurrentRequestProcessDefinitionId = () =>
  useSelector((state: RootState) => state.requests.currentRequestProcessDefinitionId);

export const useTaskState = () =>
  useSelector((state: RootState) => state.requests.task);

export const useSelectedRequest = () =>
  useSelector((state: RootState) => state.requests.selectedRequest);

export const useHiddenTaskComponentsList = () =>
  useSelector((state: RootState) => state.requests.task.hiddenComponentsList);
