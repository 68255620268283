import React, { useEffect, useState, useMemo, useRef, useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import {
  useSubmitSnackbarState,
  setSubmitSnackbarParams,
  clearSelectedRequest,
  clearTaskData,
  getMyRequestsAction,
  useCreateAfterSubmit,
  useProcessDialogState,
  openCreateDialogAction,
  setCreateRequestAction,
  setTaskLoading,
  useRequestListState,
  showDarkOverlay,
  setDraftsListRefreshStatus,
  createAfterSubmitAction,
  setCurrentRequestProcessName,
  setCurrentRequestDefinitionId,
  useCurrentRequestProcessName, useCurrentRequestProcessDefinitionId, setCreateRequestAction as setCreateRequest
} from 'store/requests';
import {
  clearSelectedApproval,
  getApprovalsAction,
  useApprovalsListState, clearSelectedReviewedCurrentTask
} from 'store/approvals';
import { setCustomModalParams } from 'store/main';
import { useFiltersState } from 'store/search';
import { useRequestParams } from 'hooks';

import {
  APPROVE_ASSIGN,
  REASSIGN,
  TAKE_ON_A_TASK
} from './constants';

import useStyles from './useStyles';

import checkmarkIcon from 'assets/images/icons/checkmark_circle_filled_white.svg';
import closeIcon from 'assets/images/icons/close-white-rounded.svg';
import { startProcessById } from '../../api/requests';
import ProcessContext from '../../contexts/ProcessContext';

export const SubmitSnackbar = () => {
  const classes = useStyles();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { open, action } = useSubmitSnackbarState();
  const createNewTask = useCreateAfterSubmit();
  const currentRequestProcessName = useCurrentRequestProcessName();
  const currentRequestProcessDefinitionId = useCurrentRequestProcessDefinitionId();
  const dialogOpen = useProcessDialogState();
  const [message, setMessage] = useState('');
  const { pathname } = useLocation();
  const { getProcessByName } = useContext(ProcessContext);

  const oldOpenState = useRef(open);
  const location = useLocation();

  const filtersParams = useFiltersState();

  const { pageSize: requestsPageSize } = useRequestListState();
  const {
    pageSize: approvalsPageSize
  } = useApprovalsListState();

  const isRequestsCompleted = useMemo(() =>
      pathname.includes('requests') && pathname.includes('completed'),
    [pathname]);

  const {
    getRequestParameters: getRequestParametersForRequests,
    urlParams: urlParamsRequests
  } = useRequestParams({
    completed: isRequestsCompleted,
    pageSize: requestsPageSize,
    currentPage: 1
  });

  const {
    getRequestParameters: getRequestParametersForApprovals,
    urlParams: urlParamsApprovals
  } = useRequestParams({
    completed: false,
    currentPage: 1,
    pageSize: approvalsPageSize,
    type: 'approvals'
  });

  const actionMap = useMemo(
    () => ({
      Create: { message: t('success_messages.create') },
      Submit: { message: t('success_messages.submit') },
      'Send to Paragraph EDO': {
        message: t('success_messages.send_to_paragraph_edo')
      },
      Done: { message: t('success_messages.done') },
      Vote: { message: t('success_messages.vote') },
      [APPROVE_ASSIGN]: {
        message: t('success_messages.approve')
      },
      [REASSIGN]: {
        message: t('success_messages.reassign')
      },
      [TAKE_ON_A_TASK]: {
        message: t('success_messages.take_on_task')
      },
      Approve: {
        message: t('success_messages.approve')
      },
      'Mass Approve': {
        message: t('success_messages.mass_approve')
      },
      Sign: {
        message: t('success_messages.sign')
      },
      Rework: {
        message: t('success_messages.rework')
      },
      'Mass Rework': {
        message: t('success_messages.mass_rework')
      },
      Reject: { message: t('success_messages.reject') },
      'Mass Reject': { message: t('success_messages.mass_reject') },
      Stop: { message: t('success_messages.stop') },
      Restart: { message: t('success_messages.restart') },
      Canceled: { message: t('success_messages.canceled') },
      default: { message: t('success_messages.delete') }
    }),
    []
  );

  const startNewProcess = useCallback(async () => {
    console.log('startNewProcess', currentRequestProcessName, currentRequestProcessDefinitionId)
    try {
      const { creator, processSysName, isTemplateProcess, camundaId, integration } = getProcessByName(currentRequestProcessName);
      const variables = {};
      if (integration) {
        variables['integration'] = {
          'type': 'String',
          'value': integration,
        };
      }
      const taskId = await startProcessById({
        params: {
          creator: creator,
          id: currentRequestProcessDefinitionId || camundaId,
          ...(isTemplateProcess && {processTemplate: true})
        },
        data: {
          processId: processSysName,
          processDefinitionName: currentRequestProcessName
        }
      });

      dispatch(
        setCreateRequest({
          id: taskId,
          name: currentRequestProcessName,
          sysName: processSysName,
          isNewRequest: true
        })
      );
      // eslint-disable-next-line no-useless-catch
    } catch (error) {
      throw error;
    }
  }, [dispatch, currentRequestProcessName, currentRequestProcessDefinitionId]);

  const handleClose = () => {
    setTimeout(() =>
      dispatch(
        setSubmitSnackbarParams({
          open: false
        })
      ), 3000);
  };

  // fix requests/approvals list not updated when page changed before snackbar hiding
  useEffect(() => {
    oldOpenState.current = false;
  }, [pathname]);

  useEffect(() => {
    setMessage(actionMap[action]?.message || actionMap.default.message);
  }, [action, actionMap, filtersParams]);

  useEffect(() => {
    if (open && oldOpenState.current !== open) {
      dispatch(showDarkOverlay(false));
      dispatch(setCustomModalParams({ open: false }));
      dispatch(clearSelectedRequest());
      dispatch(clearSelectedApproval());
      dispatch(clearSelectedReviewedCurrentTask());
      dispatch(clearTaskData());

      if (location.pathname === '/requests/active') {
        const myRequestParametersTemp = getRequestParametersForRequests(
          urlParamsRequests
        );
        const myRequestParameters = {
          ...myRequestParametersTemp,
          ...filtersParams,
          archive: false,
          completed: false,
          pending: true,
          taskCompleted: false
        };
        dispatch(getMyRequestsAction(myRequestParameters));
      }

      if (location.pathname === '/approvals/active') {
        setTimeout(() => {
          const approvalsParametersTemp = getRequestParametersForApprovals(
            urlParamsApprovals
          );

          const approvalsParameters = {
            ...approvalsParametersTemp, ...{
              ...filtersParams,
              pagination: {
                ...approvalsParametersTemp.pagination,
                ...(filtersParams?.pagination || {}),
                size: approvalsPageSize,
                ...(filtersParams?.pagination
                  ? { page: filtersParams?.pagination?.page }
                  : {})

              }
            }
          };

          dispatch(getApprovalsAction({ params: approvalsParameters }));
        });
      }

      dispatch(setDraftsListRefreshStatus(true));

      if (createNewTask && action.includes('Submit')) {
        if (startNewProcess) {
          startNewProcess();
        }
        dispatch(createAfterSubmitAction(false));
        dispatch(setCurrentRequestProcessName(''));
        dispatch(setCurrentRequestDefinitionId(''));
      } else if (dialogOpen) {
        dispatch(openCreateDialogAction(false));
        dispatch(setCreateRequestAction(null));
      }
    }

    handleClose();
  }, [open]);

  useEffect(() => {
    if (open && oldOpenState.current !== open) {
      handleClose();

      clearTaskData();
    }
  }, [open]);

  useEffect(() => {
    return () => {
      handleClose();
    };
  }, []);

  return <div className={cn(classes.wrapper, { [classes.wrapperInactive]: !action })}>
    {action &&
      (<div className={classes.root}>
        <img src={checkmarkIcon} className={classes.checkmarkIcon} />
        <span>
        {actionMap[action]?.message || actionMap.default.message}
      </span>
        <img src={closeIcon} className={classes.closeIcon} onClick={() =>
          dispatch(
            setSubmitSnackbarParams({
              open: false
            })
          )
        } />
      </div>)
    }
  </div>;
};
