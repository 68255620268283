import React, { useContext, useMemo, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RequestDetails } from '@dar-dms/utils';

import { ErrorPage, Spinner } from 'components';
import { routesList } from 'router';
import { ContentLayout } from 'layouts';

import NoAccessIcon from 'assets/images/icons/error-page-no-access-icon.svg';
import { ChangeAssigneeCallbackContext } from '../AdminPanel';
import { ChangeAssignee } from '../../components/Modal/ChangeAssignee';
import { Button, Menu, MenuItem, Modal, Typography } from '@mui/material';
import { RequestStatusesEnum } from '../AdminPanel/AdminPanel.types';
import SettingsIcon from '../../assets/images/icons/settings_icon.svg';
import { useUsersRole } from '../../hooks';
import useStyles from './useStyles';
import { ConfirmationModal } from '../../components/Modal/ConfirmationModal';
import { deleteAdminPanelRequest } from '../../api/requests';
import { clearTaskData, useProcessesListState } from '../../store/requests';
import { useDispatch } from 'react-redux';
import { useUsersState } from '../../store/users';
import { readTask, readTaskAttachments, readTaskComments } from '../../store/main';

export const RequestPageContent = (
  {
    loading,
    readyToShowRequest,
    requestClosed,
    changeAssigneeData,
    setRequestClosed,
    setChangeAssigneeData
  }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id: paramId } = useParams<{ id?: string }>();
  const { isUserAdmin } = useUsersRole();

  const { data: processDefinitions } = useProcessesListState();

  const users = useUsersState();
  const usersList = useMemo(() => Object.values(users.users), [users]);

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const [isVisibleDeleteModal, setIsVisibleDeleteModal] = useState(false);
  const [bpmTask, setBpmTask] = useState(null);

  const ButtonManageRequest = useMemo(() => {
    if (loading || !isUserAdmin && !bpmTask?.isCompleted) {
      return null;
    }

    return (
      <Button onClick={(e) => {
        setAnchorEl(e.currentTarget);
      }} color="secondary" className={classes.button}>
        <img src={SettingsIcon} alt="settings icon" />
        {t('buttons.manageRequest')}
      </Button>
    );
  }, [isUserAdmin, loading]);

  const changeAssigneeContext = useContext(ChangeAssigneeCallbackContext);

  const handleChangeAssignee = (bpmTask, step, assigneeId, taskId = '') => {
    changeAssigneeContext.action({
      assigneeId,
      taskId: taskId || step.taskId,
      stepName: t(`constructor-${bpmTask.processSysName}.actions.${step.actionSysName}.name`, { defaultValue: step.taskName }),
    });
  };

  const handleDeleteRequest = async () => {
    if (!bpmTask?.processInstanceId) return;
    return deleteAdminPanelRequest(bpmTask?.processInstanceId);
  };

  const onSuccessDeleteRequest = () => {
    dispatch(clearTaskData());
    NotificationManager.success(t('customProcesses.notifications.deletion_success'));
    history.replace('/approvals');
  };

  if (!readyToShowRequest || loading) {
    return <Spinner />;
  }

  if (readyToShowRequest && !requestClosed) {
    return (
      <ContentLayout title={''} zIndexContent={101}>
        <RequestDetails
          open
          fullScreen
          id={paramId}
          idType={'processInstanceId'}
          onTaskLoad={(loadedBpmTask) => setBpmTask(loadedBpmTask)}
          onTaskRead={(taskId) => {dispatch(readTask(taskId))}}
          onTaskCommentsRead={(taskId) => dispatch(readTaskComments(taskId))}
          onTaskAttachmentsRead={(taskId) => dispatch(readTaskAttachments(taskId))}
          onClose={() => setRequestClosed(true)}
          canChangeAssignee={isUserAdmin}
          handleChangeAssignee={handleChangeAssignee}
          extraActionButtons={ButtonManageRequest}
          shouldLoadExtraInfo={false}
          initialProcessesList={processDefinitions}
          initialUsersList={usersList}
        />

        <Modal open={Boolean(changeAssigneeData && changeAssigneeData?.assigneeId)} classes={{root: classes.modalRoot}} disableEnforceFocus>
          <ChangeAssignee
            data={
              changeAssigneeData
                ? {
                  assigneeId: changeAssigneeData?.assigneeId,
                  taskId: changeAssigneeData?.taskId,
                  stepName: changeAssigneeData?.stepName,
                }
                : null
            }
            close={() => setChangeAssigneeData(null)}
            onSuccess={() => window.location.reload()}
          />
        </Modal>

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          disableEnforceFocus
          classes={{
            root: classes.menuRoot
          }}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
            style: {
              width: 200,
              border: '1px solid rgba(38, 40, 66, 0.08)',
              boxShadow: '0px 8px 16px rgba(38, 40, 66, 0.04)',
              background: 'white',
              borderRadius: 10,
            },
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PaperProps={{
            style: {
              paddingBottom: 8,
              border: 'none',
              boxShadow: 'none',
              background: 'transparent',
            },
          }}
        >
          {!bpmTask?.businessTask.parallel && bpmTask?.businessTask.pending && bpmTask?.businessTask.assignee && (
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                setChangeAssigneeData({
                  assigneeId: bpmTask.businessTask.assignee,
                  taskId: bpmTask.businessTask.taskId,
                  stepName: t(`constructor-${bpmTask.businessTask.processSysName}.actions.${bpmTask.currentAction.sysName}.name`, {
                    defaultValue: bpmTask.currentAction.name,
                  }),
                });
              }}
            >
              <Typography>{t('AdminPanel.actions.changeAssignee')}</Typography>
            </MenuItem>
          )}

          <MenuItem
            onClick={() => {
              setIsVisibleDeleteModal(true);
              setAnchorEl(null);
            }}
          >
            <Typography>{t('customProcesses.table.actions.delete')}</Typography>
          </MenuItem>
        </Menu>

        <Modal open={isVisibleDeleteModal} classes={{root: classes.modalRoot}}>
          <ConfirmationModal
            description={t('AdminPanel.actions.deleteRequest')}
            title={t('AdminPanel.actions.deleteRequest')}
            okButtonText={t('customProcesses.creationPage.buttons.delete')}
            close={() => {
              setIsVisibleDeleteModal(false);
            }}
            onSuccessAction={onSuccessDeleteRequest}
            action={handleDeleteRequest}
          />
        </Modal>
      </ContentLayout>
    );
  }

  if (readyToShowRequest && requestClosed) {
    return (
      <Redirect
        to={`${
          routesList(t).approvals.path + routesList(t).approvals.submenu[0].path
        }`}
      />
    );
  }

  return (
    <ErrorPage
      icon={NoAccessIcon}
      title={t('errorPage.noAccess.title')}
      message={''}
    />
  );
};

export default RequestPageContent;
