import { MouseEvent, useEffect, useMemo, useState, useRef } from 'react';
import { NotificationManager } from 'react-notifications';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import { useDialogState, useUsersRole } from 'hooks';
import { changeCurrentWorkspace } from 'utils/workspace';
import {
  activateTemplateProcessRequest,
  deactivateTemplateProcessRequest,
  deleteTemplateProcessRequest,
  getSettingsProcessCompanyRequest,
  getDepartmentsList,
  activateTemplateProcessAutostart,
  deactivateTemplateProcessAutostart,
  getTemplateProcessBySysName,
  getTemplateProcessDraftBySysName,
  getAvailableLanguages,
  duplicateProcess,
  createTemplateFromProcess,
  getTemplateBySysName,
  getTemplateDraftBySysName,
} from 'api/requests';

import { StatusType } from 'types';
import {
  DepartmentInfo,
  ProcessStep,
  TemplateProcess,
  TemplateProcessSettings,
  TemplateProcessType,
} from './TemplateProcesses.types';
import { sendCustomEvent } from '../../utils/analytics';

const CANCEL_TEMPLATE_REASON = "cancel templateGetById"

export const useTemplateProcesses = (filterValue, processId) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [abortController, setAbortController] = useState<AbortController>(null)

  const routeMatch = useRouteMatch();

  const isTemplatesPage = useMemo(() => {
    return routeMatch.path === "/templates/:id" || routeMatch.path === "/templates"
  }, [routeMatch])

  const { areTemplateProcessesAccessesLoading } = useUsersRole();

  const {
    isDialogOpen: isCreateTemplateDialogOpen,
    handleDialogOpen: handleCreateTemplateDialogOpen,
    handleDialogClose: handleCreateTemplateDialogClose,
  } = useDialogState();

  const {
    isDialogOpen: isCongratulationsDialogOpen,
    handleDialogOpen: handleCongratulationsDialogOpen,
    handleDialogClose: handleCongratulationsDialogClose,
  } = useDialogState();

  const {
    isDialogOpen: isSetupTemplateDialogOpen,
    handleDialogOpen: handleSetupTemplateDialogOpen,
    handleDialogClose: handleSetupTemplateDialogClose,
  } = useDialogState();

  const {
    isDialogOpen: isArchiveConfirmationDialogOpen,
    handleDialogOpen: handleArchiveConfirmationDialogOpen,
    handleDialogClose: handleArchiveConfirmationDialogClose,
  } = useDialogState();

  const {
    isDialogOpen: isPublishedProcessesLimitDialogOpen,
    handleDialogOpen: handlePublishedProcessesLimitDialogOpen,
    handleDialogClose: handlePublishedProcessesLimitDialogClose,
  } = useDialogState();

  const {
    isDialogOpen: isProcessDeleteConfirmationDialogOpen,
    handleDialogOpen: handleProcessDeleteConfirmationDialogOpen,
    handleDialogClose: handleProcessDeleteConfirmationDialogClose,
  } = useDialogState();

  const {
    isDialogOpen: isCopyProcessDialogOpen,
    handleDialogOpen: handleCopyProcessDialogOpen,
    handleDialogClose: handleCopyProcessDialogClose,
  } = useDialogState();

  const {
    isDialogOpen: isSaveAsTemplateDialogOpen,
    handleDialogOpen: handleSaveAsTemplateDialogOpen,
    handleDialogClose: handleSaveAsTemplateDialogClose,
  } = useDialogState();

  const {
    isDialogOpen: isDuplicateProcessDialogOpen,
    handleDialogOpen: handleDuplicateProcessDialogOpen,
    handleDialogClose: handleDuplicateProcessDialogClose,
  } = useDialogState();

  const {
    isDialogOpen: isConfirmProcessCopyOpenDialogOpen,
    handleDialogOpen: handleConfirmProcessCopyOpenDialogOpen,
    handleDialogClose: handleConfirmProcessCopyOpenDialogClose,
  } = useDialogState();

  const {
    isDialogOpen: isTemplatesListDialogOpen,
    handleDialogOpen: handleTemplatesListDialogOpen,
    handleDialogClose: handleTemplatesListDialogClose
  } = useDialogState();

  const [needToShowCongratulationDialog, setNeedToShowCongratulationDialog] = useState<boolean>(true);

  const [loadingStatus, setLoadingStatus] = useState<StatusType>(StatusType.PENDING);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [templateProcesses, setTemplateProcesses] = useState<Partial<TemplateProcess>[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [anchorElement, setAnchorElement] = useState<Element | null>(null);
  const [autostartAnchorElement, setAutostartAnchorElement] = useState<Element | null>(null);
  const [currentRow, setCurrentRow] = useState<Partial<TemplateProcess> | null>(null);
  const [currentProcess, setCurrentProcess] = useState<TemplateProcess | null>(null);
  const [currentProcessPublishedVersion, setCurrentProcessPublishedVersion] = useState<TemplateProcess | null>(null);
  const [templateSettings, setTemplateSettings] = useState<TemplateProcessSettings | null>(null);
  const [needResetProcessForm, setNeedResetProcessForm] = useState<boolean>(false);
  const [areValuesChanged, setValuesChanged] = useState<boolean>(false);
  const [departmentsList, setDepartmentsList] = useState<DepartmentInfo[]>([]);
  const [availableLocalesList, setAvailableLocalesList] = useState<string[]>([]);

  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);

  const [processCopyParams, setProcessCopyParams] = useState<{[key: string]: any}>({});

  const isProcessJustOpened = useRef(false);

  const handleAnchorReset = (): void => {
    setAnchorElement(null);
  };

  const location = useLocation();

  const handleAutostartAnchorReset = (): void => {
    setAutostartAnchorElement(null);
  };

  const handleNewTemplateProcessDialogOpen = async (isListOpen?: boolean) => {
    if (isListOpen) {
      handleTemplatesListDialogOpen()
      return
    }
    sendCustomEvent('Requests_Process_Builder', 'Process_Builder_opened_via_click_create_from_scratch', 'Process_Builder_opened_via_create_from_scratch')
    setCurrentRow(null);
    setCurrentProcess(null);
    setCurrentProcessPublishedVersion(null);
    handleCreateTemplateDialogOpen();
  };

  const handleActionsClick = (event: MouseEvent<HTMLAnchorElement> | MouseEvent<HTMLButtonElement>, row: TemplateProcess): void => {
    setCurrentRow(row);
    setAnchorElement(event.currentTarget);
  };

  const handleAutostartActionsClick = (event: MouseEvent<HTMLElement> | MouseEvent<HTMLButtonElement>, row: TemplateProcess | null): void => {
    if (row !== null) {
      setCurrentRow(row);
    }

    setAutostartAnchorElement(event.currentTarget);
  };

  const handleProcessEditClick = async () => {
    try {
      setValuesChanged(() => false);
      setLoading(true);

      let publishedProcessData = null
      let processDraftData = null;
      if (isTemplatesPage){
        publishedProcessData = await getTemplateBySysName(currentRow.processSysName);
        processDraftData = await getTemplateDraftBySysName(currentRow.processSysName);
      } else {
        publishedProcessData = await getTemplateProcessBySysName(currentRow.processSysName);
        processDraftData = await getTemplateProcessDraftBySysName(currentRow.processSysName);
      }

      setCurrentProcess(processDraftData);
      setCurrentProcessPublishedVersion(publishedProcessData);
      const formValues = processDraftData.languages;
      setTemplateSettings({
        formProcess: formValues,
        processIcon: processDraftData.iconPath,
        processGroup: processDraftData.category,
      });

      handleSetupTemplateDialogOpen();
      isProcessJustOpened.current = true;

      if (isTemplatesPage){
        history.replace(`/templates/${currentRow.processSysName}`);
      } else {
        history.replace(`/template-processes/${currentRow.processSysName}`, { fromButton: 'editButton' });
      }

      setTimeout(() => {
        isProcessJustOpened.current = false;
      }, 3000);
    } catch (error) {
      console.log(error)
      console.log('Error loading process info');
      NotificationManager.error(t(error?.message || 'errors.somethingIsWrong'), `${t('customProcesses.notifications.error')}!`);
    } finally {
      setLoading(false);
    }
  };

  const handleProcessOpenBySysName = async (processSysName) => {
      const controller = new AbortController()
      setAbortController(controller);

    try {
      setValuesChanged(() => false);
      setLoading(true);
      let publishedProcessData = null
      let processDraftData = null;
      if (isTemplatesPage){
        publishedProcessData = await getTemplateBySysName(processSysName, controller.signal);
        processDraftData = await getTemplateDraftBySysName(processSysName, controller.signal);
      } else {
        publishedProcessData = await getTemplateProcessBySysName(processSysName);
        processDraftData = await getTemplateProcessDraftBySysName(processSysName);
      }
      setCurrentProcess(processDraftData);
      setCurrentProcessPublishedVersion(publishedProcessData);
      const formValues = processDraftData.languages;

      setTemplateSettings({
        formProcess: formValues,
        processIcon: processDraftData.iconPath,
        processGroup: processDraftData.category,
      });
      handleSetupTemplateDialogOpen();
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (controller?.signal.aborted && controller?.signal.reason === CANCEL_TEMPLATE_REASON){
        return;
      }

      console.log('Error loading process info');
      NotificationManager.error(t(error?.message || 'errors.somethingIsWrong'), `${t('customProcesses.notifications.error')}!`);
    } finally {
      setLoading(false);
    }
  };

  const handleProcessNameClick = async (row: Partial<TemplateProcess>) => {
    try {
      setValuesChanged(() => false);
      setLoading(true);
      setCurrentRow(row);

      let publishedProcessData = null
      let processDraftData = null

      if (isTemplatesPage){
        publishedProcessData = await getTemplateBySysName(row.processSysName);
        processDraftData = await getTemplateDraftBySysName(row.processSysName);
      } else {
        publishedProcessData = await getTemplateProcessBySysName(row.processSysName);
        processDraftData = await getTemplateProcessDraftBySysName(row.processSysName);
      }

      setCurrentProcess(processDraftData);
      setCurrentProcessPublishedVersion(publishedProcessData);
      const formValues = processDraftData.languages;
      setTemplateSettings({
        formProcess: formValues,
        processIcon: processDraftData.iconPath,
        processGroup: processDraftData.category,
      });
      handleSetupTemplateDialogOpen();
      isProcessJustOpened.current = true;

      if (isTemplatesPage){
        history.replace(`/templates/${row.processSysName}`);
      } else {
        history.replace(`/template-processes/${row.processSysName}`);
      }

      setTimeout(() => {
        isProcessJustOpened.current = false;
      }, 3000);
    } catch (error) {
      console.log('Error loading process info');
      NotificationManager.error(t(error?.message || 'errors.somethingIsWrong'), `${t('customProcesses.notifications.error')}!`);
    } finally {
      setLoading(false);
    }
  };

  const onCreateProcessDialogClose = () => {
    setNeedResetProcessForm(() => true);
    handleCreateTemplateDialogClose();
  };

  const onCopyProcessDialogOpen = async (processData) => {
      setCurrentRow(processData);
      handleCopyProcessDialogOpen();
  };


  const onSaveAsTemplateOpen = (processData) => {
    setCurrentRow(processData);
    handleSaveAsTemplateDialogOpen();
  };


  const onDuplicateProcessDialogOpen = (processData) => {
    setCurrentRow(processData);
    handleDuplicateProcessDialogOpen();
  };

  const handleProcessCopy = async (processCopyName: string, selectedWorkspaces: string[], refreshProcessesList = false, actionType: 'copy' | 'duplicate', openCreatedProcess = false) => {
    try {
      const copiedProcessData = await getTemplateProcessDraftBySysName(currentRow.processSysName);
      copiedProcessData.processName = processCopyName;
      copiedProcessData.languages[0].processName = processCopyName;
      if (actionType === 'copy') {
        copiedProcessData.category = 'custom_process_template';
      }
      const newProcessIds = await duplicateProcess(copiedProcessData, selectedWorkspaces);
      if (actionType === 'copy') {
        NotificationManager.success(t('customProcesses.notifications.copy_success'), `${t('customProcesses.notifications.success')}!`);
        if (openCreatedProcess) {
          const processDuplicateId = newProcessIds[0];
          const targetWorkspaceId = selectedWorkspaces[0];
          history.replace(`/template-processes/${processDuplicateId}`, undefined);
          changeCurrentWorkspace(targetWorkspaceId);
        }
      } else {
        NotificationManager.success(t('customProcesses.notifications.duplicate_success'), `${t('customProcesses.notifications.success')}!`);
        handleDuplicateProcessDialogClose();
        handleSetupTemplateDialogClose();
        if (openCreatedProcess) {
          const processDuplicateId = newProcessIds[0];
          const processDuplicateData = { processSysName: processDuplicateId } as Partial<TemplateProcess>;
          await handleProcessNameClick(processDuplicateData);
        }
      }
      if (refreshProcessesList) {
        await getSettingsProcessCompany();
      }
      return true;
    } catch (error) {
      console.log('Error copying|duplicating process', error);
      NotificationManager.error(`${t('customProcesses.notifications.error')}: ${error?.message}`, `${t('customProcesses.notifications.error')}!`);
      return false;
    }
  };

  const handleSaveAsTemplate = async(name: string, description: string, category: string, open?: boolean): Promise<boolean>  => {
    try {
     const process = await getTemplateProcessDraftBySysName(currentRow.processSysName);

     process.processName = name;
     process.languages[0].processName = name;
     process.category = category;

     process.description = description;
     process.languages[0].description = description;

    const newTemplate = await createTemplateFromProcess(process)

    if (open){
      history.replace(`/templates/${newTemplate.processSysName}`);
      return true
    }

    NotificationManager.success(t('templates.notifications.templateSaved'));
    return true

    } catch (error){
     NotificationManager.error(`${t('customProcesses.notifications.error')}: ${error?.message}`, `${t('customProcesses.notifications.error')}!`);
     return false
    }
   }

  const handleTemplateProcessesDialogSubmit = (templateSettings: TemplateProcessSettings): void => {
    setTemplateSettings(templateSettings);

    handleCreateTemplateDialogClose();
    handleSetupTemplateDialogOpen();

    if (needToShowCongratulationDialog && !currentRow) {
      handleCongratulationsDialogOpen();
      setNeedToShowCongratulationDialog(false);
    }
  };

  const handleBackButtonClick = (): void => {
    handleSetupTemplateDialogClose();
    isProcessJustOpened.current = false;

    if (isTemplatesPage){
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      abortController.abort(CANCEL_TEMPLATE_REASON);
      history.replace('/templates');
    } else {
      history.replace('/template-processes');
    }

    setNeedResetProcessForm(() => true);
  };

  const handleTemplateProcessActivate = async (): Promise<void> => {
    try {
      await activateTemplateProcessRequest(currentRow.processSysName);
      NotificationManager.success(t('customProcesses.notifications.activation_success'), `${t('customProcesses.notifications.success')}!`);
    } catch (error) {
      NotificationManager.error(`${t('customProcesses.notifications.error')}: ${error?.message}`, `${t('customProcesses.notifications.error')}!`);
    } finally {
      await getSettingsProcessCompany();
    }
  };

  const handleTemplateProcessDeactivate = async (): Promise<void> => {
    try {
      await deactivateTemplateProcessRequest(currentRow.processSysName);
      NotificationManager.success(t('customProcesses.notifications.archive_success'), `${t('customProcesses.notifications.success')}!`);
    } catch (error) {
      NotificationManager.error(`${t('customProcesses.notifications.error')}: ${error?.message}`, `${t('customProcesses.notifications.error')}!`);
    } finally {
      await getSettingsProcessCompany();

      if (isSetupTemplateDialogOpen) {
        handleSetupTemplateDialogClose();
      }
    }
  };

  const handleTemplateProcessDelete = async (): Promise<void> => {
    try {
      await deleteTemplateProcessRequest(processId || currentRow?.processSysName);
      handleSetupTemplateDialogClose();
      setNeedResetProcessForm(true);
      history.replace(`/template-processes`);

      NotificationManager.success(t('customProcesses.notifications.deletion_success'), `${t('customProcesses.notifications.success')}!`);
    } catch (error) {
      NotificationManager.error(`${t('customProcesses.notifications.error')}: ${error?.message}`, `${t('customProcesses.notifications.error')}!`);
    } finally {
      await getSettingsProcessCompany();
    }
  };

  const handleAutostartStart = async (): Promise<void> => {
    try {
      await activateTemplateProcessAutostart(currentRow.processName);

      NotificationManager.success(t('customProcesses.notifications.autostart_started_success'), `${t('customProcesses.notifications.success')}!`);
    } catch (error) {
      NotificationManager.error(`${t('customProcesses.notifications.error')}: ${error?.message}`, `${t('customProcesses.notifications.error')}!`);
    } finally {
      await getSettingsProcessCompany();
    }
  };

  const handleAutostartStop = async (): Promise<void> => {
    try {
      await deactivateTemplateProcessAutostart(currentRow.processName);
      NotificationManager.success(t('customProcesses.notifications.autostart_stopped_success'), `${t('customProcesses.notifications.success')}!`);
    } catch (error) {
      NotificationManager.error(`${t('customProcesses.notifications.error')}: ${error?.message}`, `${t('customProcesses.notifications.error')}!`);
    } finally {
      await getSettingsProcessCompany();
    }
  };

  const getSettingsProcessCompany = async (): Promise<void> => {
    if (!filterValue) return
    setErrorMessage(null);
    setLoadingStatus(StatusType.PENDING);

    try {
      const data = await getSettingsProcessCompanyRequest(filterValue);

      setTotalPages(data.totalPages);
      setTotalElements(data.totalElements);
      setLoadingStatus(StatusType.RESOLVED);
      setTemplateProcesses([
        ...data.content.map((process: Partial<TemplateProcess>) => ({ ...process, type: TemplateProcessType.PROCESS })),
      ]);
    } catch (error) {
      setErrorMessage(t(error?.message || 'errors.somethingIsWrong'));
      setLoadingStatus(StatusType.REJECTED);

      throw error;
    }
  };

  const getDepartmentsInfo = async () => {
    try {
      const departments = await getDepartmentsList();
      setDepartmentsList(departments);
    } catch (e) {
      console.log('Error loading departments list');
    }
  };

  useEffect((): void => {
    getDepartmentsInfo();
  }, []);

  const getAvailableLocales = async () => {
    try {
      const locales = await getAvailableLanguages();
      setAvailableLocalesList(locales.map(item => item.id));
    } catch (e) {
      console.log('Error loading locales list');
    }
  };

  useEffect((): void => {
    getAvailableLocales();
  }, []);

  useEffect(() => {
    if(processId) return;
    getSettingsProcessCompany();
  }, [filterValue, processId]);

  useEffect(() => {
    if (isCreateTemplateDialogOpen || isSetupTemplateDialogOpen) {
      window.onbeforeunload = (event) => {
        const e = event || window.event;
        e.preventDefault();
        if (e) {
          e.returnValue = '';
        }
        return '';
      };
    } else {
      window.onbeforeunload = null;
    }
  }, [isCreateTemplateDialogOpen, isSetupTemplateDialogOpen]);

  useEffect(() => {
    if (!processId || isProcessJustOpened?.current) {
      return;
    }

    if (areTemplateProcessesAccessesLoading) {
      setLoading(true);
    } else {
      handleProcessOpenBySysName(processId);
    }
  }, [processId, areTemplateProcessesAccessesLoading]);

  return {
    isCreateTemplateDialogOpen,
    isCongratulationsDialogOpen,
    isSetupTemplateDialogOpen,
    loading,
    loadingStatus,
    errorMessage,
    anchorElement,
    autostartAnchorElement,
    templateProcesses,
    templateSettings,
    currentRow,
    currentProcess,
    currentProcessPublishedVersion,
    departmentsList,
    availableLocalesList,
    areValuesChanged,
    needResetProcessForm,
    setNeedResetProcessForm,
    setValuesChanged,
    setTemplateSettings,
    getSettingsProcessCompany,
    handleAnchorReset,
    handleAutostartAnchorReset,
    handleActionsClick,
    handleAutostartActionsClick,
    handleProcessNameClick,
    handleProcessEditClick,
    handleProcessOpenBySysName,
    onCreateProcessDialogClose,
    handleCreateTemplateDialogOpen,
    handleCongratulationsDialogClose,
    handleSetupTemplateDialogOpen,
    handleSetupTemplateDialogClose,
    handleTemplateProcessesDialogSubmit,
    handleTemplateProcessActivate,
    handleTemplateProcessDeactivate,
    handleTemplateProcessDelete,
    handleAutostartStart,
    handleAutostartStop,
    handleBackButtonClick,
    handleSaveAsTemplate,
    handleNewTemplateProcessDialogOpen,
    isArchiveConfirmationDialogOpen,
    handleArchiveConfirmationDialogOpen,
    handleArchiveConfirmationDialogClose,
    isPublishedProcessesLimitDialogOpen,
    handlePublishedProcessesLimitDialogOpen,
    handlePublishedProcessesLimitDialogClose,
    isProcessDeleteConfirmationDialogOpen,
    handleProcessDeleteConfirmationDialogOpen,
    handleProcessDeleteConfirmationDialogClose,
    isCopyProcessDialogOpen,
    handleCopyProcessDialogOpen,
    handleCopyProcessDialogClose,
    isDuplicateProcessDialogOpen,
    handleDuplicateProcessDialogClose,
    isConfirmProcessCopyOpenDialogOpen,
    handleConfirmProcessCopyOpenDialogOpen,
    handleConfirmProcessCopyOpenDialogClose,
    onCopyProcessDialogOpen,
    handleProcessCopy,
    processCopyParams,
    setProcessCopyParams,
    onDuplicateProcessDialogOpen,
    totalPages,
    totalElements,
    isTemplatesListDialogOpen,
    handleTemplatesListDialogOpen,
    handleTemplatesListDialogClose,
    onSaveAsTemplateOpen,
    handleSaveAsTemplateDialogClose,
    isSaveAsTemplateDialogOpen,
    setCurrentRow
  }
}
