import React, { ReactElement } from 'react';
import {
  Box,
  Button,
  IconButton, Modal,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { useLocation, useRouteMatch } from 'react-router-dom';

import useDeleteProcessDialogStyles from './DeleteProcessDialog.useStyles';
import { sendCustomEvent } from '../../../../utils/analytics';

interface DeleteProcessDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
}

export const DeleteProcessDialog = ({ isOpen, onClose, onDelete }: DeleteProcessDialogProps): ReactElement => {
  const { t } = useTranslation();
  const classes = useDeleteProcessDialogStyles();
  const location = useLocation();
  const routeMatch = useRouteMatch();


  const handleDelete = () => {
    if (routeMatch.params?.hasOwnProperty('id')) {
      // @ts-ignore
      if(location?.state?.fromButton === 'editButton') {
        sendCustomEvent('Requests_Custom_Processes', 'Delete_process_from_edit_process', 'DeleteProcessFromEditProcess')
      } else {
        sendCustomEvent('Requests_Custom_Processes', 'Delete_process_from_click_process', 'DeleteProcessFromClickProcess')
      }
    } else {
      sendCustomEvent('Requests_Custom_Processes', 'Delete_process_from_3_points', 'DeleteProcessFrom3Points');
    }
    onDelete();
    onClose();
  };

  return (
    <Modal open={isOpen}>
      <>
        <Box className={classes.modalBody}>
          <div className={classes.modalHeader}>
            <Typography className={classes.title}>
              {t('customProcesses.creationPage.deleteProcessModal.title')}
            </Typography>

            <IconButton onClick={onClose} size="large">
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          </div>

          <div className={classes.modalContent}>
            <Typography className={classes.description}>
              {t('customProcesses.creationPage.deleteProcessModal.description')}
            </Typography>
          </div>

          <div className={classes.modalFooter}>
            <Button color="secondary" variant="contained" onClick={onClose} className={classes.modalButton}>
              {t('comment_modal.button_cancel')}
            </Button>

            <Button color="primary" onClick={handleDelete} className={classes.modalButton}>
              {t('customProcesses.creationPage.buttons.delete')}
            </Button>
          </div>
        </Box>
      </>
    </Modal>
  );
};
