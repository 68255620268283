import React, { useCallback, useContext, useMemo } from 'react';
import { Box, ButtonBase, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { useTaskState } from 'store/requests';
import { useUserProfile } from 'hooks';

import { Assignee } from './Assignee';
import {
  StructuredHistoryParallelAssigneesStep,
  StructuredHistoryStep,
} from './history.types';

import ArrowTertiary from 'assets/images/icons/history-arrow-tertiary.svg';
import CircleTertiary from 'assets/images/icons/history-circle-tertiary.svg';
import EditRegular from 'assets/images/icons/edit_regular.svg';

import useStyles from './useStyles';
import { ChangeAssigneeCallbackContext } from 'pages/AdminPanel/AdminPanel';

// TODO: refactor, move change assignee in HistoryTab

type Props = {
  step: StructuredHistoryParallelAssigneesStep
  isFirstStep: boolean;
  isLastStep: boolean;
  companyId: string;
  hideTimeline?: boolean;
  branchStepItem?: boolean;
  topBranchStepItem?: boolean;
};

export const HistoryTabParallelAssigneesStep = ({
  step,
  isFirstStep = false,
  isLastStep = false,
  companyId,
  hideTimeline = false,
  branchStepItem = false,
  topBranchStepItem = false,
}: Props) => {
  const classes = useStyles({
    isStepCompleted: (step.assigneeSteps[0]?.data as StructuredHistoryStep)?.completed,
    isLastStep,
    isFutureStep: (step.assigneeSteps[0]?.data as StructuredHistoryStep)?.isFutureStep,
    isParallelStep: (step.assigneeSteps[0]?.data as StructuredHistoryStep)?.parallel,
    isOpenedStep: (step.assigneeSteps[0]?.data as StructuredHistoryStep)?.isOpenedStep,
    isBranchStep: branchStepItem,
  });

  const changeAssigneeContext = useContext(ChangeAssigneeCallbackContext);

  const { data: bpmTask } = useTaskState();
  const { t } = useTranslation();
  const { companyName } = useUserProfile();

  const getAssigneeList = useCallback((assignee: string) => {
    if (assignee?.includes(',') || assignee?.includes(';')) {
      const separator = assignee?.includes(',') ? ',' : ';';
      return assignee?.split(separator).map((assignee) => assignee.trim());
    }

    return [];
  }, []);

  const handleChangeAssignee = (assigneeId: string, taskId?: string) => {
    changeAssigneeContext.action({
      assigneeId,
      taskId: taskId,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      stepName: t(`constructor-${bpmTask.processSysName}.actions.${(step.assigneeSteps[0]?.data as StructuredHistoryStep)?.actionSysName}.name`, { defaultValue: (step.assigneeSteps[0]?.data as StructuredHistoryStep)?.taskName }),
    });
  };

  const timelineIcon = useMemo(() => {
    if (isFirstStep || isLastStep) {
      return CircleTertiary;
    }

    return ArrowTertiary;
  }, [isFirstStep, isLastStep]);

  const stepInfo = useMemo(() => {
    if (step.isFutureNode) {
      return <>
        <span className={classes.multipleAssigneesLabel}>
          {t('task_history.parallelAssigneesLabel')}{' '}({(step.assigneeSteps[0]?.data as StructuredHistoryStep)?.assigneeList?.length})
        </span>

        <div className={classes.parallelStepInfoWrapper}>
          <div
            className={classes.historyItemDataFutureStep}>
            {(step.assigneeSteps[0]?.data as StructuredHistoryStep)?.assigneeList.map((assignee, index, arr) =>
              <Box display="flex" gap={6} mb={index === arr.length - 1 ? 0 : 3}>
                <Assignee assignee={assignee} companyId={companyId}/>
              </Box>,
            )}
          </div>
        </div>
      </>;
    }

    return <>
        <span className={classes.multipleAssigneesLabel}>
          {t('task_history.parallelAssigneesLabel')}{' '}({step?.assigneeSteps?.length})
        </span>
      {step.assigneeSteps.map(assigneeStep => (
        <div className={classes.parallelStepInfoWrapper}>
          <Box display="flex" gap={6}>
            <Assignee assignee={(assigneeStep?.data as StructuredHistoryStep)?.assignee} companyId={companyId} />
            {
              changeAssigneeContext?.isAvailiable &&
              changeAssigneeContext?.action &&
              (assigneeStep?.data as StructuredHistoryStep).isCurrentStep &&
              !(assigneeStep?.data as StructuredHistoryStep).completed &&
              <ButtonBase
                onClick={() => {
                  handleChangeAssignee((assigneeStep?.data as StructuredHistoryStep)?.assignee, (assigneeStep?.data as StructuredHistoryStep)?.taskId);
                }}
                className={classes.editBtn}><img src={EditRegular} alt="edit" /></ButtonBase>
            }
          </Box>

          {!(assigneeStep?.data as StructuredHistoryStep)?.isFutureStep &&
            <Typography className={classes.taskStatus}>
              {(assigneeStep?.data as StructuredHistoryStep)?.completed
               ?
               <>
             <span className={cn({
               [classes.taskStatusApprove]: (assigneeStep?.data as StructuredHistoryStep)?.displayApproveStageType === 'approve',
               [classes.taskStatusRework]: (assigneeStep?.data as StructuredHistoryStep)?.displayApproveStageType === 'rework',
               [classes.taskStatusReject]: (assigneeStep?.data as StructuredHistoryStep)?.displayApproveStageType === 'reject',
               [classes.taskStatusReject]: (assigneeStep?.data as StructuredHistoryStep)?.displayApproveStageType === 'interrupted',
             })}>{(assigneeStep?.data as StructuredHistoryStep)?.displayApproveStage}</span>
                 {'  '}{(assigneeStep?.data as StructuredHistoryStep)?.completionDateString}
               </>
               :
                <span className={classes.taskStatusWaitingApproval}>{t('task_history.waitingApproval')}</span>
              }
            </Typography>
          }
        </div>
      ))}
    </>;
  }, [step]);

  return (
    <Box className={cn(classes.historyItemWrapper, {
      [classes.historyItemWrapperNoTimeline]: hideTimeline,
      [classes.historyItemWrapperBranchStep]: branchStepItem,
      [classes.historyItemWrapperTopBranchStep]: topBranchStepItem,
    })}>
      {!hideTimeline &&
        <div className={classes.timelinePart}>
          <div className={classes.timelinePoint}>
            <img src={timelineIcon} alt=""/>
          </div>
          {!isFirstStep && <div className={classes.timelineLine}></div>}
        </div>
      }

      <div className={cn(classes.historyItemData, { [classes.historyItemDataNoTimeline]: hideTimeline })}>
        <Typography className={classes.taskName}>
          {t(`constructor-${bpmTask.processSysName}.actions.${(step.assigneeSteps[0]?.data as StructuredHistoryStep)?.actionSysName}.name`, { defaultValue: (step.assigneeSteps[0]?.data as StructuredHistoryStep)?.taskName })}
        </Typography>
        {stepInfo}
      </div>
    </Box>
  );
};
