import { useSelector } from 'react-redux';

import type { RootState } from 'store';

export const useApprovalsListState = () =>
  useSelector((state: RootState) => state.approvals.approvalsList);

export const useSelectedApproval = () =>
  useSelector((state: RootState) => state.approvals.selectedApproval);

export const useSelectedReviewedCurrentTask = () =>
  useSelector((state: RootState) => state.approvals.selectedReviewedCurrentTask);

export const useVisiblePanel = () =>
  useSelector((state: RootState) => state.approvals.visiblePanel);

export const useMassApproveActionStatus = () =>
  useSelector((state: RootState) => state.approvals.massApproveActionStatus);
