import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, ButtonGroup, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import cn from 'classnames';

import { useTaskState } from 'store/requests';

import { StructuredHistoryCondition } from './history.types';
import {
  CONDITION_TYPES,
} from 'pages/TemplateProcesses/components/StepConditionModal/StepConditionModal.constants';

import ConditionIconTertiary from 'assets/images/icons/history-condition-icon-tertiary.svg';
import ConditionIconGrey from 'assets/images/icons/history-condition-icon-grey.svg';

import useConditionItemStyles from './useConditionItemStyles';
import { useFormatConditionValue } from 'pages/TemplateProcesses/useFormatConditionValue';
import { HistoryTabNode } from './HistoryTabNode';
import { useHoldingTranslationsLoadedState } from 'store/main';
import { limitStringLength } from 'utils/user';

type Props = {
  condition: StructuredHistoryCondition;
  nodeIndex: number;
  nodeCount: number;
  companyId: string;
};

export const HistoryTabConditionItem = ({
  condition,
  nodeIndex,
  nodeCount,
  companyId,
}: Props) => {
  const isPassedCondition = useMemo(() =>
      !condition.isFutureNode
    , [condition]);
  const holdingTranslationsLoaded = useHoldingTranslationsLoadedState();

  const { formatConditionValue, conditionValueFormatted } = useFormatConditionValue();

  const classes = useConditionItemStyles({ isPassedCondition });

  const { data: bpmTask } = useTaskState();

  const { t } = useTranslation();

  const [toggleState, setToggleState] = useState(true);

  const timelineIcon = useMemo(() =>
      isPassedCondition ? ConditionIconTertiary : ConditionIconGrey
    , [isPassedCondition]);

  const conditionSentenceTemplateKey = useMemo(() =>
      CONDITION_TYPES[condition.fieldType]?.find(item => item.key === condition?.condition)?.template || ''
    , [condition]);

  const conditionFieldName = useMemo(() => {
    const allParameters = bpmTask.states.reduce((acc, v) => [...acc, ...(v.bsnParameters || [])], []);
    const fieldAttribute = bpmTask?.attributes?.find(attribute => attribute?.name === condition?.field);
    const stepAttribute = allParameters?.find(attr => attr?.attributeId === fieldAttribute?.typeAttributeId);
    const attributeHint = stepAttribute?.hint || fieldAttribute?.hint;
    if (fieldAttribute) {
      const hintTranslationKey = `constructor-${bpmTask.processSysName}.states.${condition.actionSysName || bpmTask.currentAction.sysName}.attributes.${fieldAttribute.sysName.replaceAll('::', '-')}.hint`;
      const hintTranslationNewFormatKey = `constructor-${bpmTask.processSysName}.attributes.${condition.actionSysName || fieldAttribute.sysName.replaceAll('::', '-')}.hint`;
      return t(hintTranslationKey, { defaultValue: t(hintTranslationNewFormatKey, { defaultValue: attributeHint }) });
    }
  }, [bpmTask, condition, condition, holdingTranslationsLoaded]);

  const getFormattedConditionValue = useCallback(async () => {
    if (!condition) return;

    const allParameters = bpmTask.states.reduce((acc, v) => [...acc, ...(v.bsnParameters || [])], []);
    const fieldAttribute = bpmTask?.attributes?.find(attribute => attribute?.name === condition?.field);
    const stepAttribute = allParameters?.find(attr => attr?.attributeId === fieldAttribute?.typeAttributeId);

    formatConditionValue({
      fieldType: condition.fieldType,
      value: condition.value,
      fieldParams: JSON.parse(stepAttribute?.componentParams || '{}') || {}
    });
  }, [condition]);

  useEffect(() => {
    getFormattedConditionValue();
  }, [getFormattedConditionValue]);

  useEffect(() => {
    if (isPassedCondition && condition?.actualResult) {
      setToggleState(condition?.actualResult?.[0] === condition?.positiveStep?.[0]);
    }
  }, [condition, isPassedCondition]);

  const conditionBranchStateDescription = useMemo(() => limitStringLength(conditionValueFormatted, 25), [conditionValueFormatted]);

  return (
    <>
      <div className={classes.conditionBranchWrapper}>
        {(toggleState ? condition.positiveBranch : condition.negativeBranch)
          .map(branchNode =>
            <HistoryTabNode
              node={branchNode}
              nodeIndex={nodeIndex}
              nodeCount={nodeCount}
              companyId={companyId}
            />,
          )}
      </div>

      <Box className={classes.historyItemWrapper}>
        <div className={classes.timelinePart}>
          <div className={classes.timelinePoint}>
            <img src={timelineIcon} alt=""/>
          </div>
          <div className={classes.timelineLine}></div>
        </div>

        <div
          className={cn(classes.historyConditionItemData, { [classes.historyConditionItemDataFutureStep]: !isPassedCondition })}>
          <Box className={classes.leftSide}>
            <Typography className={classes.conditionName}>
              {t('task_data_view.history_tab.conditionNamePrefix')}: {t(`constructor-${bpmTask.processSysName}.scripts.${condition.stepperOrder}.name`, { defaultValue: condition.scriptName })}
            </Typography>
            <Typography className={classes.conditionDescription} title={conditionValueFormatted}>
              <Trans
                i18nKey={conditionSentenceTemplateKey}
                values={{ field: conditionFieldName, value: conditionBranchStateDescription }}
              >
                If <span>conditionFieldName</span> is less than <span>conditionValueFormatted</span>
              </Trans>
              {isPassedCondition && <>{' -> '}<span>{toggleState
                                                     ? t('task_data_view.history_tab.conditionToggle.yes')
                                                     : t('task_data_view.history_tab.conditionToggle.no')}</span></>}
            </Typography>
          </Box>

          <Box className={classes.rightSide}>
            {!isPassedCondition && <>
              <Typography className={classes.conditionToggleLabel}>
                {t('task_data_view.history_tab.conditionToggleLabel')}
              </Typography>
              <ButtonGroup variant="outlined" className={classes.conditionToggleButtons} color="secondary">
                <Button
                  onClick={() => setToggleState(true)}
                  className={cn({ [classes.conditionToggleActiveButton]: toggleState })}
                >
                  {t('task_data_view.history_tab.conditionToggle.yes')}
                </Button>

                <Button
                  onClick={() => setToggleState(false)}
                  className={cn({ [classes.conditionToggleActiveButton]: !toggleState })}
                >
                  {t('task_data_view.history_tab.conditionToggle.no')}
                </Button>
              </ButtonGroup>
            </>}
          </Box>
        </div>
      </Box>
    </>
  );
};
