import React, { memo, ReactNode, ReactElement, useMemo } from 'react';
import { NotificationManager } from 'react-notifications';

import { Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import {
  ContentHeader,
  SubmitSnackbar,
  DarkOverlay,
  MainTabs,
} from 'components';
import { clearSelectedApproval, clearSelectedReviewedCurrentTask, useSelectedApproval } from 'store/approvals';
import {
  clearSelectedRequest,
  setSubmitSnackbarParams,
  useProcessesListState,
  useSelectedRequest,
  useTaskState
} from 'store/requests';

import useStyles from './useStyles';
import { RequestDetails } from '@dar-dms/utils';
import { useDispatch } from 'react-redux';
import { useUsersState } from '../../store/users';
import { readTask, readTaskComments, readTaskAttachments } from '../../store/main';

type ContentLayoutProps = {
  children?: ReactNode;
  hasMainMenuTabs?: boolean;
  headerChild?: ReactNode;
  title?: string;
  zIndexContent?: number;
};

export const ContentLayout = memo(
  ({
    children,
    hasMainMenuTabs = true,
    headerChild,
    title = null,
    zIndexContent,
  }: ContentLayoutProps): ReactElement => {
    const classes = useStyles({ zIndexContent });
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const dispatch = useDispatch();

    const { data: processDefinitions } = useProcessesListState();

    const { users } = useUsersState();
    const usersList = useMemo(() => Object.values(users), [users]);

    const { initialTab } = useTaskState();
    const selectedApprovalId = useSelectedApproval();
    const selectedRequestId = useSelectedRequest();
    const isTaskDetailsOpen = Boolean(selectedApprovalId || selectedRequestId);
    const openRequestId = selectedApprovalId || selectedRequestId;

    const onTaskComplete = (action) => {
      // NotificationManager.success(t(ACTIONS_NOTIFICATIONS[action] || ACTIONS_NOTIFICATIONS.default));
      dispatch(
        setSubmitSnackbarParams({
          open: true,
          action: action,
        })
      );
      clearSelectedTask();
    }

    const clearSelectedTask = () => {
      dispatch(clearSelectedRequest());
      dispatch(clearSelectedApproval());
      dispatch(clearSelectedReviewedCurrentTask());
    }

    const contentClass = useMemo(() => {
      switch (pathname) {
        case '/requests/active':
        case '/requests/completed':
        case '/requests/drafts':
        case '/approvals/active':
        case '/approvals/reviewed':
          return classes.contentRequests;
        default:
          return classes.content;
      }
    }, [pathname, classes]);

    const backgroundWrapperClass = useMemo(() => {
      switch (pathname) {
        case '/requests/active':
        case '/requests/completed':
        case '/requests/drafts':
          return classes.backgroundPartWrapperRequests;
        case '/approvals/active':
        case '/approvals/reviewed':
        case '/monitoring/active':
        case '/monitoring/completed':
          return classes.backgroundPartWrapperApprovals;
        default:
          return null;
      }
    }, [pathname, classes]);

    const headerClass = useMemo(() => {
      switch (pathname) {
        case '/requests/active':
        case '/requests/completed':
        case '/requests/drafts':
          return classes.headerRequests;
        default:
      }
    }, [pathname, classes]);

    const mainTabsClass = useMemo(() => {
      switch (pathname) {
        case '/requests/active':
        case '/requests/completed':
        case '/requests/drafts':
          return classes.mainTabsRequests;
        default:
      }
    }, [pathname, classes]);

    return (
      <>
        <Box className={classes.root}>
          <Box className={classes.contentWrapper}>
            <Box className={contentClass}>
              <ContentHeader
                className={headerClass}
                title={title || t('Processes.module')}
              >
                {headerChild}
              </ContentHeader>
              {hasMainMenuTabs && (
                <Box className={mainTabsClass}>
                  <MainTabs />
                </Box>
              )}
              <div
                className={cn({
                  [backgroundWrapperClass]: pathname !== '/analytics',
                })}
              >
                {children}
              </div>
            </Box>
            {isTaskDetailsOpen && (
              <RequestDetails
                open={isTaskDetailsOpen}
                onClose={clearSelectedTask}
                onTaskComplete={onTaskComplete}
                onTaskLoadError={() => NotificationManager.error(t('errors.somethingIsWrong'))}
                onTaskRead={(taskId) => {dispatch(readTask(taskId))}}
                onTaskCommentsRead={(taskId) => dispatch(readTaskComments(taskId))}
                onTaskAttachmentsRead={(taskId) => dispatch(readTaskAttachments(taskId))}
                id={openRequestId}
                idType={'taskId'}
                initialTab={initialTab}
                shouldLoadExtraInfo={false}
                initialProcessesList={processDefinitions}
                initialUsersList={usersList}
                shouldShowSuccessNotification={false}
              />
            )}
          </Box>
        </Box>
        <DarkOverlay />
        <SubmitSnackbar />
      </>
    );
  }
);
